import React from "react";
import {
    Box,
    Button,
    Card,
    Checkbox,
    Group,
    MantineStyleSystemProps,
    SimpleGrid,
    Text,
} from "@mantine/core";

import classes from "./ControlSection.module.css";
import cx from "classnames";

interface ControlSectionProps {
    title: string;
    items: string[];
    selectedItems: string[];
    onIndicatorClick: () => void;
    onChange: (values: string[]) => void;
    cols: number;
    width: MantineStyleSystemProps["w"],
    error?: boolean
    required?: boolean
}

const ControlSection: React.FC<ControlSectionProps> = ({
    title,
    items,
    selectedItems,
    onIndicatorClick,
    onChange,
    cols,
    width,
    error,
    required
}) => {
    return (
        <Card bg="var(--secondary-color)" mb="md" p="0" className={cx(classes.Card, {[classes.CardError]: error})} w={width}>
            <Box p="0" bg="var(--secondary-color)">
                <Group position="apart" p="sm" pb="sm" className={classes.CardAccent}>
                    <Text size="sm" weight={600}>{title} {required && <span className={classes.Required}>*</span>}</Text>
                    {error && <Text size="xs">Please select at least one value</Text>}
                    <Button 
                        variant="subtle" 
                        size="sm"
                        onClick={onIndicatorClick}
                    >
                        {selectedItems.length === 0 ? "Select All" : "Deselect All"}
                    </Button>
                </Group>
                    <Checkbox.Group
                        value={selectedItems}
                        onChange={onChange}
                        size="md"
                        bg="var(--primary-color)"
                    >
                        <SimpleGrid cols={cols} spacing="xs" p="md" h="14rem">
                            {items.map((item) => (
                                <Checkbox
                                    key={item}
                                    label={item}
                                    value={item}
                                    radius="sm"
                                    color="var(--secondary-color)"
                                    styles={{
                                        label: {
                                            textTransform: "capitalize",
                                        }
                                    }}
                                />
                            ))}
                        </SimpleGrid>
                    </Checkbox.Group>
            </Box>
        </Card>
    );
};

export default ControlSection;