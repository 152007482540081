import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import {
    Accordion,
    Text,
    Group,
    Box,
    LoadingOverlay,
    Tooltip,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../../store/system/systemActions";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import Icon from "@mdi/react";
import { mdiCloudDownload, mdiFolderDownload, mdiLayersTriple } from "@mdi/js";
import classes from "./Downloads.module.css";
import { useLayerDownloadsAPI } from "crud/hooks/data_layers";
import { useApiQuery } from "hooks/useAPI";
import { ReportDownloadSchema } from "crud/reportDownloadsCRUD";
import { useOktaAuth } from "@okta/okta-react";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { ConfigMenuGroup, ConfigMenuLayer } from "store/system/systemTypes";
import cx from "classnames"

interface DownloadsProps {
    reportId: string;
    layerId: string | null;
}
interface LayerDownload {
    name: string;
    id: string;
    layer_id: string;
    parent_names: string;
    beta: boolean;
}

interface TreeNode {
    name: string;
    id: string;
    children: TreeNode[];
    layerData?: LayerDownload;
}

const ReportDownloads: React.FC<DownloadsProps> = ({ reportId, layerId }) => {
    const dispatch = useDispatch();

    const { authState } = useOktaAuth();

    const { data: externalDLData } =
        useApiQuery<ReportDownloadSchema[]>(
            `/events/reports/${reportId}/downloads`,
            ["reports", reportId, "externalDownloads"],
        );
    
    let menuIndex = useSelector(
        (state) =>
            getStoreAtNamespaceKey(state, "report").layersConfig.menuIndex,
    );

    const { trackUserEventWithCurrentEvent } = useAnalytics();
    const [pathToNode, setPathToNode] = useState<string[]>([]);

    const findPathToNode = useCallback(
        (
            nodes: TreeNode[],
            targetId: string,
            path: string[] = [],
        ): string[] => {
            for (const node of nodes) {
                if (
                    node.layerData?.layer_id === targetId ||
                    node.id === targetId
                ) {
                    return [...path, node.layerData?.layer_id || node.id];
                }
                if (node.children.length > 0) {
                    const childPath = findPathToNode(node.children, targetId, [
                        ...path,
                        node.layerData?.layer_id! || node.id,
                    ]);
                    if (childPath.length > 0) {
                        return childPath;
                    }
                }
            }
            return [];
        },
        [],
    );

    const buildTree = useCallback((layers: LayerDownload[]): TreeNode[] => {
        const globalNodeMap = new Map<string, TreeNode>();
        
        const getOrCreateNode = (name: string, id: string): TreeNode => {
            if (!globalNodeMap.has(id)) {
                globalNodeMap.set(id, { name, id, children: [] });
            }
            return globalNodeMap.get(id)!;
        };
        
        layers.forEach((layer) => {
            const parentNames = layer.parent_names
                .split(",")
                .filter((name) => name.trim() !== "");

            let currentNode = getOrCreateNode(
                layer.name,
                layer?.layer_id || layer.id,
            );
            currentNode.layerData = layer;

            let parentNode: TreeNode | null = null;
            parentNames.forEach((parentName) => {
                parentNode = getOrCreateNode(parentName, parentName);
                if (
                    !parentNode.children.some(
                        (child) =>
                            child.layerData?.layer_id ===
                            currentNode.layerData?.layer_id,
                    )
                ) {
                    parentNode.children.push(currentNode);
                }
                currentNode = parentNode;
            });
        });
        // Find root nodes (nodes that are not children of any other node)
        const rootNodes = Array.from(globalNodeMap.values()).filter(
            (node) =>
                !Array.from(globalNodeMap.values()).some(
                    (potentialParent) =>
                        potentialParent !== node &&
                        potentialParent.children.includes(node),
                ),
        );
        return rootNodes;
    }, []);

    const { data, isLoading } = useLayerDownloadsAPI(reportId);

    const layerTree = useMemo(() => {
        if (data && data.length > 0) {
            return buildTree(data);
        }
        return [];
    }, [data, buildTree]);

    useEffect(() => {
        if (layerId && layerTree) {
            const path = findPathToNode(layerTree, layerId);
            setPathToNode(path);
        }
    }, [findPathToNode, layerTree, layerId]);

    const getSASURL = async (
        layerId: string,
        layerName: string,
    ) => {
        try {
            const response = await axios.get(
                `${
                    import.meta.env.VITE_API_ROOT
                }/events/layers/${layerId}/download`,
                {
                    headers: {
                        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                    },
                },
            );
            window.open(response.data.data.blob_url, "_blank");
            trackUserEventWithCurrentEvent({
                name: "data_layer_download_clicked",
                payload: {
                    layerName,
                    dataLayerId: layerId,
                },
            });
        } catch (error) {
            console.error("Failed to download layer:", error);
            dispatch(
                setAlert({
                    message: "Failed to download",
                    timeout: 5000,
                    type: "Error",
                }),
            );
        }
    };

    const shouldGroupBeRendered = (group: ConfigMenuGroup): boolean => {
        return group.children.some(child => {
            if (child.type === "layer") {
                return !!data?.find(layer => layer.layer_id === (child as ConfigMenuLayer).layerSource);
            } else if (child.type === "group") {
                return shouldGroupBeRendered(child);
            }
            return false;
        });
    };

    const renderLayerTree = (nodes: (ConfigMenuGroup | ConfigMenuLayer)[], isTopLevel: boolean = true) => {
        let hasDownloadableContent = false;
    
        const LayerTree = nodes.map((node) => {
            if (node.type === "group") {
                if (shouldGroupBeRendered(node)) {
                    hasDownloadableContent = true;
                    return (
                        <Accordion.Item key={node.id} value={node.groupName}>
                            <Accordion.Control>
                                <div className={cx(classes.GroupItem, classes.ListItem)}>
                                    <p className={classes.ListItemText}>{node.groupName}</p>
                                </div>
                            </Accordion.Control>
                            <Accordion.Panel>
                                {renderLayerTree(node.children, false)}
                            </Accordion.Panel>
                        </Accordion.Item>
                    );
                }
                return null;
            } 
    
            let layerNode = node as ConfigMenuLayer;
            let downloadAvailable = !!data?.find((layer) => layer.layer_id === layerNode.layerSource);
            if (downloadAvailable) {
                hasDownloadableContent = true;
                return (
                    <div className={classes.ListItem}>
                        <p className={classes.ListItemText}>{layerNode.layerName}</p>
                        <Tooltip label={"Download Layer"} position={"left"}>
                            <div 
                                className={classes.DownloadIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    getSASURL(
                                        data?.find(
                                            (layer) =>
                                                layer.layer_id === layerNode.layerSource
                                        )?.id!,
                                        layerNode?.layerName,
                                    );
                                }}
                            >
                                <Icon path={mdiCloudDownload} size="2rem" />

                            </div>
                        </Tooltip>
                    </div>
                )
            }
            return null;
        });
    
        if (isTopLevel && !hasDownloadableContent) {
            return (
                <Text c="dimmed">
                    There are no downloadable layer files available for this event
                </Text>
            );
        }
    
        return (
            <Accordion  
                classNames={{
                    item: classes.AccordionItem,
                    label: classes.AccordionLabel
                }}
                variant="separated"
            >
                {LayerTree}
            </Accordion>
        )
    };

    const renderExternalDownloads = () => {
        if (!externalDLData) return null;

        if (!Object.keys(externalDLData).length)
            return (
                <Text c="dimmed">
                    There are no downloadable external files available for this
                    event
                </Text>
            );

        const downloadEntries = externalDLData;

        if (downloadEntries.length === 1) {
            return (
                <Box className={classes.accordionControlBox}>
                    <Group position="apart">
                        <Text>{downloadEntries[0].name}</Text>
                        <div
                            onClick={() => {
                                window.open(downloadEntries[0].url, "_blank");
                                    trackUserEventWithCurrentEvent({
                                        name: "external_downloads_download_clicked",
                                        payload: {
                                            name: downloadEntries[0].name,
                                        },
                                    });
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <Icon path={mdiCloudDownload} size="2rem" />
                        </div>
                    </Group>
                </Box>
            );
        }

        return downloadEntries.map((reportDownload) => (
                <Group position="apart">
                <Text>{reportDownload.name}</Text>
                <div
                    onClick={() => {
                        window.open(
                            reportDownload.url,
                            "_blank",
                        );
                        trackUserEventWithCurrentEvent({
                            name: "external_downloads_download_clicked",
                            payload: {
                                name: reportDownload.name,
                            },
                        });
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <Icon path={mdiCloudDownload} size="2rem" />
                </div>
            </Group>
        ))
    };


    if ((layerId && pathToNode.length === 0) || isLoading)
        return <LoadingOverlay visible />;

    return (
        <Accordion
            w="100%"
            variant="filled"
            className={classes.accordionContainer}
            bg="var(--primary-color)"
            defaultValue={"layers"}
        >
            <Accordion.Item value="layers">
                <Accordion.Control>
                    <Group>
                        <Icon size="2rem" path={mdiLayersTriple} />
                        <Text>Layer Downloads</Text>
                    </Group>
                </Accordion.Control>
                <Accordion.Panel>
                    <Accordion
                        defaultValue={
                            pathToNode.length > 0 ? pathToNode[0] : null
                        }
                        styles={{ content: { paddingBottom: 0 } }}
                    >
                        {menuIndex && menuIndex.length > 0 ? (
                            renderLayerTree(menuIndex)
                        ) : (
                            <Text c="dimmed">
                                There are no downloadable layer files available
                                for this event
                            </Text>
                        )}
                    </Accordion>
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="external">
                <Accordion.Control>
                    <Group>
                        <Icon size="2rem" path={mdiFolderDownload} />
                        <Text>External Downloads</Text>
                    </Group>
                </Accordion.Control>
                <Accordion.Panel>
                    <Accordion>{renderExternalDownloads()}</Accordion>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
};

export default ReportDownloads;
