import React, {
    FC,
    lazy,
    Suspense,
    useCallback,
    useEffect,
    useState,
} from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import * as H from "history";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { RestoreOriginalUriFunction } from "@okta/okta-react/bundles/types/OktaContext";
import { Provider, useDispatch, useSelector } from "react-redux";

import {
    getHomepageStore,
    getAdminPageStore,
    getReportPageStore,
    getStoreAtNamespaceKey,
} from "../../store/storeSelectors";

import classes from "./App.module.css";
import LoadingScreen from "../_Library/LoadingScreen/LoadingScreen";
import { oktaAuthConfig, oktaSignInConfig } from "../../config";
import TermsOfService from "./Permissions/TermsOfService/TermsOfService";
import { RootState } from "../../store/store";
import { ExtendedAccessManagementUser, GEOUserWithPortfolios, MisgisUserClaims } from "../../types/auth";
import { initiateUser, setUser } from "store/user/userActions";
import LoginPage from "../Pages/LoginPage/LoginPage";
import EventIdReport from "../Pages/EventIdReport/EventIdReport";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import Error404Page from "components/Pages/404Page/404Page";
import ErrorBoundary from "components/_Library/Errors/ErrorBoundary";
import { Notifications } from "@mantine/notifications";
import useUserPlugins from "./useUserPlugins";
import { useUserMeAccessQuery } from "crud/me_access";
import Onboarding from "components/Pages/Onboarding/Onboarding";

const Homepage = lazy(() => import("components/Pages/Homepage/Homepage"));
const NavBar = lazy(() => import("components/Partials/NavBar/NavBar"));

const AboutUsPage = lazy(
    () => import("components/Pages/AboutUsPage/AboutUsPage"),
);
const AdminPage = lazy(() => import("components/Pages/AdminPage/AdminPage"));
const ResetPasswordPage = lazy(
    () => import("components/Pages/ResetPasswordPage/ResetPasswordPage"),
);

// const SharePage = lazy(() => import("components/Pages/SharePage/SharePage"));

const oktaAuth = new OktaAuth(oktaAuthConfig);

const Router: FC = () => {
    const history: H.History = useHistory();
    const [loading, setLoading] = useState(true);
    const onAuthRequired = useCallback(() => {
        history.push("/login");
    }, [history]);
    const restoreOriginalUri: RestoreOriginalUriFunction = useCallback(
        (_oktaAuth, originalUri) => {
            history.replace(toRelativeUrl(originalUri, window.location.origin));
        },
        [history],
    );

    const dispatch = useDispatch();
    const user: ExtendedAccessManagementUser = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user!,
    );
    const location = useLocation();
    let { identify } = useAnalytics();
    useUserPlugins({ user });
    
    const { data } = useUserMeAccessQuery();

    useEffect(() => {
        if (data?.access_expiry_days === null || data?.access_expiry_days === undefined) return;
        if (data.access_expiry_days !== undefined && data.access_expiry_days > 0 && data.access_expiry_days <= 30) {
            Notifications.show({
                message: `Your access expires in ${data.access_expiry_days} day${data.access_expiry_days > 1 ? "s": ""}, after which you will no longer be able to access GEO`,
                color: "var(--highlight-color)",
                autoClose: false,
            })
        }
    }, [data]);

    
    useEffect(() => {
        oktaAuth.isAuthenticated().then(async (authenticated) => {
            if (!user && authenticated && location.pathname !== "/login") {
                oktaAuth.getUser().then(async (user) => {
                    const initiatedUser = await dispatch(
                        initiateUser(
                            user as MisgisUserClaims,
                            oktaAuth.getAccessToken() || "",
                            identify,
                        ),
                    );
                    setLoading(false);
                    // initiateUser needs its types fixed, then we can avoid this
                    // but for now it does the trick
                    dispatch(setUser(initiatedUser as unknown as GEOUserWithPortfolios));
                });
            } else {
                setLoading(false);
            }

            if (user) {
                if (
                    ["development", "staging"].includes(
                        import.meta.env.VITE_ENVIRONMENT,
                    ) &&
                    !user.groups.includes("MIS Staff")
                ) {
                    await oktaAuth.revokeAccessToken();
                    await oktaAuth.closeSession();
                    history.push("/login");
                }
            }
            if (!user.industry && !location.pathname.includes("/onboarding")) {
                history.push("/onboarding");
            }
        });
    }, [dispatch, history, identify, location, user]);

    if (loading && (!location.pathname.includes("onboarding"))) {
        return <LoadingScreen />;
    }

    return (
        <>
            <Security
                oktaAuth={oktaAuth}
                onAuthRequired={onAuthRequired}
                restoreOriginalUri={restoreOriginalUri}
            >
                {/*Insecure Routes*/}
                <Route
                    path="/login"
                    render={() => <LoginPage config={oktaSignInConfig} />}
                />
                <Route path="/login/callback" component={LoginCallback} />

                {/*Lazy loaded components */}
                <Suspense fallback={<LoadingScreen />}>
                    <Switch>
                        <Route
                            path="/forgot-password"
                            render={() => <ResetPasswordPage />}
                        />

                        {/*Secure Routes*/}
                        <SecureRoute
                            path="/"
                            exact={true}
                            render={(renderProps) => {                                
                                return (
                                    <Provider store={getHomepageStore()}>
                                        <ErrorBoundary>
                                            <div
                                                className={
                                                    classes.PageContainer
                                                }
                                            >
                                                <NavBar />
                                                <Homepage />
                                            </div>
                                        </ErrorBoundary>
                                    </Provider>
                                );
                            }}
                        />

                        <SecureRoute
                            exact
                            path="/report/:eventId/:longitude?/:latitude?/:zoom?"
                            render={(routeProps) => (
                                <Provider store={getReportPageStore()}>
                                    <ErrorBoundary>
                                        <div className={classes.PageContainer}>
                                            <NavBar />
                                            <EventIdReport
                                                key={routeProps.match.params.id}
                                            />
                                        </div>
                                    </ErrorBoundary>
                                </Provider>
                            )}
                        />

                        <SecureRoute
                            path="/about"
                            render={() => {
                                return <AboutUsPage />;
                            }}
                        />

                        {/* Onboarding Routes */}
                        <SecureRoute
                            path="/onboarding"
                            render={() => {
                                return <Onboarding />
                            }}
                        />

                        {user?.is_admin && (
                            <SecureRoute
                                path="/admin"
                                render={() => {
                                    return (
                                        <Provider store={getAdminPageStore()}>
                                            <ErrorBoundary>
                                                <AdminPage />
                                            </ErrorBoundary>
                                        </Provider>
                                    );
                                }}
                            />
                        )}
                        <SecureRoute
                            path={"*"}
                            render={() => (
                                <>
                                    <NavBar />
                                    <Error404Page />
                                </>
                            )}
                        />
                    </Switch>
                    
                </Suspense>
                {/* Inside Router due to requirement for okta*/}
                <TermsOfService />
            </Security>
        </>
    );
};

export default Router;
