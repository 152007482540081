/**
 * Generates a ref to hold the map's geocoder search box.
 */

import React, { FC, useState } from "react";

import classes from "./GeocoderTab.module.css";
import Geocoder from "./Geocoder/Geocoder";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { Result } from "@mapbox/mapbox-gl-geocoder";
import SearchHistory from "./SearchHistory/SearchHistory";

export interface SearchesType {
    searches: Result[];
}

export interface SearchType {
    result: Result;
}

const GeocoderTab: FC = () => {
    const geocoderContainerRef: React.RefObject<HTMLDivElement> =
        React.createRef();

    const mapRef = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "ref").mapRef,
    );

    const [pastSearches, setPastSearches] = useState<SearchesType>({
        searches: [],
    });

    return (
        <>
            <div className={classes.Container} ref={geocoderContainerRef} id={"report-searchTab-search"}>
                <Geocoder
                    containerRef={geocoderContainerRef}
                    mapRef={mapRef}
                    pastSearches={pastSearches}
                    setPastSearches={setPastSearches}
                />
            </div>

            {pastSearches.searches.length ?
                <div>
                    <SearchHistory
                        mapRef={mapRef}
                        pastSearches={pastSearches}
                        setPastSearches={setPastSearches}
                    />
                </div> 
                : null
            }
            
        </>
    );
};

export default GeocoderTab;
