import classes from "./ViewTabs.module.css";
import { IconButton } from "../../../../_Library/Button/IconButton";
import Icon from "@mdi/react";
import { mdiChartBar, mdiFormatListBulleted, mdiMap } from "@mdi/js";
import cx from "classnames";
import React, { FC } from "react";

type ViewTabsProps = {
    onMapClick: () => void;
    onInsightsClick: () => void;
    onReportClick: () => void;
    mapActive: boolean;
    insightsActive: boolean;
    insightsAvailable: boolean;
};

export const ViewTabs: FC<ViewTabsProps> = ({
    onMapClick,
    onInsightsClick,
    onReportClick,
    mapActive,
    insightsActive,
    insightsAvailable,
}) => {
    return (
        <>
            <div className={classes.Container}>
                <div className={cx(classes.TabBG, { [classes.Active]: mapActive })}>
                    <IconButton
                        onClick={() => onMapClick()}
                        active={mapActive}
                        iconComponent={<Icon path={mdiMap} size={1.5} />}
                        label={"Map"}
                        className={classes.Tab}
                        id={"report-tabmenu-map"}
                    />
                </div>
                <div className={classes.TabBG}>
                    <IconButton
                        onClick={() => onReportClick()}
                        active={false}
                        iconComponent={
                            <Icon path={mdiFormatListBulleted} size={1.5} />
                        }
                        label={"Report"}
                        className={classes.Tab}
                        id={"report-tabmenu-report"}
                    />
                </div>
                <div
                    className={cx(classes.TabBG, {
                        [classes.Active]: insightsActive,
                    })}
                >
                    <IconButton
                        onClick={() => onInsightsClick()}
                        active={insightsActive}
                        iconComponent={<Icon path={mdiChartBar} size={1.5} />}
                        label={"Insights"}
                        className={classes.Tab}
                        id='report-tabmenu-insights'
                    />
                    {insightsAvailable && !insightsActive && (
                        <div className={classes.BadgeContainer}>
                            <div className={classes.Badge} />
                            <div className={classes.Pulse} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};