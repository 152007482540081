import React from 'react'
import classes from "./Onboarding.module.css"
import { Link, useHistory } from 'react-router-dom'
import { motion } from "framer-motion"
import ControlSection from 'components/_Library/ControlSection/ControlSection'
import Icon from '@mdi/react'
import { mdiArrowDownCircleOutline, mdiArrowUpCircleOutline } from '@mdi/js'
import { LINES_OF_BUSINESS } from 'store/user/utils'
import cx from "classnames"
import { FormValues } from './Onboarding'
import { UseFormReturnType } from '@mantine/form'

interface LinesOfBusinessProps {
    form: UseFormReturnType<FormValues>
    updateLocalStorage: () => void
}

const LinesOfBusiness: React.FC<LinesOfBusinessProps> = ({form, updateLocalStorage}) => {

    const history = useHistory()

    const handleNextStep = () => {
        const validationResult = form.validate()
        const validToProceed = !['lines_of_business'].some(key => key in validationResult.errors);

        if (validToProceed) {
            updateLocalStorage()
            history.push("/onboarding/3") 
        }    
    }

    return (
        <motion.div 
            className={classes.Container}
            initial={{
                y: "50vh",
                opacity: 0,
            }}
            animate={{
                y: "0",
                opacity: 1,
                transitionDuration: "0.5s"
            }}
            exit={{
                y: "-50vh",
                opacity: 0,
            }}
        >
            <div className={classes.ContinueContainer} onClick={updateLocalStorage}>
                <Link to="1">
                    <Icon className={classes.PreviousIcons} path={mdiArrowUpCircleOutline} size={2}/>
                </Link>   
            </div>
             <div className={cx(classes.FormContainer, classes.ControlSectionContainer)}>
                <ControlSection
                    title="Lines of Business"
                    required
                    items={LINES_OF_BUSINESS}
                    selectedItems={
                        form.getInputProps('lines_of_business').value
                    }
                    onIndicatorClick={
                        () => {
                            if (form.getInputProps('lines_of_business').value.length !== 0) {
                                form.setFieldValue("lines_of_business", [])
                            } else {
                                form.setFieldValue("lines_of_business", LINES_OF_BUSINESS)

                            }
                        }
                    }
                    onChange={
                        (e) => {
                            form.setFieldValue("lines_of_business", e)
                        }
                    }
                    cols={3}
                    width="100%"
                    error={form.getInputProps('lines_of_business').error}
                />
             </div>
            
             <div className={classes.ContinueContainer} onClick={handleNextStep}>
                <Icon className={classes.ContinueIcons} path={mdiArrowDownCircleOutline} size={5}/>  
            </div>
            
        </motion.div>
    )
}

export default LinesOfBusiness