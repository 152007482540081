/**
 * Layer List item is used in the layer tab component and links the layer displayed in map with its options.
 */

import React, { useCallback, useState } from "react";
import * as MapboxGL from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { mdiFilterOff, mdiTable } from "@mdi/js";
import cx from "classnames";
import Icon from "@mdi/react";
import { mdiDotsHorizontal } from "@mdi/js/commonjs/mdi";

import { RootState } from "store/store";
import {
    setLayerFilter,
    setLayerView,
    setLayerVisibility,
    setLegendPopup,
} from "store/report/reportActions";
import Legend from "../../../../Legend/Legend";
import LayerIcon from "../../../../LayerIcon/LayerIcon";
import { isZoomToBBox } from "utils/TypeGuards";
import ScrollableText from "../../../../../../../../_Library/ScrollableText/ScrollableText";
import { getStoreAtNamespaceKey } from "../../../../../../../../../store/storeSelectors";
import { getCssVar } from "utils/CSSHelpers";
import classes from "./LayerListItem.module.css";
import Toggle from "../../../../../../../../_Library/Inputs/Toggle/Toggle";
import { Badges } from "components/_Library/Badges/Badges";
import { Popover, Tooltip, ActionIcon, Modal } from "@mantine/core";
import { CustomLayerTable } from "./CustomLayerTable/CustomLayerTable";
import LayerContextMenu from "../LayerContextMenu/LayerContextMenu";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { LayerActions, Visibility } from "store/report/reportTypes";
import { AnyLayer } from "react-map-gl";

interface OwnProps {
    id: string;
    layerName: string;
    sourceName: string;
    visibility: Visibility;
    viewOn: "left" | "both" | "right";
    paint: MapboxGL.AnyPaint;
    layout: MapboxGL.AnyLayout;
    layerType: AnyLayer["type"];
    complexPaintProperties: Array<keyof MapboxGL.AnyPaint>;
    actions?: LayerActions;
    isCustomLayer: boolean;
    beta?: boolean;
    tier?: string;
    downloadsAvailable?: boolean;
}

const LayerListItem: React.FC<OwnProps> = ({
    id,
    layerName,
    sourceName,
    visibility,
    viewOn,
    paint,
    layerType,
    layout,
    complexPaintProperties,
    actions,
    isCustomLayer,
    beta,
    tier,
    downloadsAvailable,
}) => {
    const dispatch = useDispatch();
    const [legendOpen, setLegendOpen] = useState(false);
    const [geoDataTableActive, setGeoDataTableActive] = useState(false);

    const highlightedLayer = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").highlightedLayer,
    );
    const legendPopup = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").legendPopup,
    );
    const mapRef = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "ref").mapRef,
    );
    const layerFilters = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").layerFilters,
    );

    const { trackUserEventWithCurrentEvent } = useAnalytics();

    const toggleLayerVisibility = useCallback(() => {
        dispatch(
            setLayerVisibility({
                sourceName: sourceName,
                layerName: layerName,
                visibility: visibility === "visible" ? "none" : "visible",
            }),
        );
        trackUserEventWithCurrentEvent({
            name: "data_layer_visibility_toggled",
            payload: {
                layer_id: id,
                layer_name: layerName,
                state: visibility === "visible" ? "off" : "on",
                view: viewOn,
            },
        });
    }, [
        dispatch,
        sourceName,
        layerName,
        visibility,
        trackUserEventWithCurrentEvent,
        id,
        viewOn,
    ]);
    const handleZoomToLayer = useCallback(() => {
        let zoomTo = actions?.zoomTo;

        if (isZoomToBBox(zoomTo)) {
            const bbox = zoomTo.bbox;
            mapRef.current?.fitBounds(bbox, {
                padding: 50,
            });
        }

        trackUserEventWithCurrentEvent({
            name: "go_to_clicked",
            payload: {
                layer_id: id,
                layer_name: layerName,
            },
        });
    }, [
        actions?.zoomTo,
        trackUserEventWithCurrentEvent,
        id,
        layerName,
        mapRef,
    ]);

    const renderContextMenu = useCallback(() => {
        return (
            <LayerContextMenu
                onZoomTo={handleZoomToLayer}
                onViewSelection={(view) =>
                    dispatch(
                        setLayerView({ layerName, sourceName, viewOn: view }),
                    )
                }
                currentView={viewOn}
                downloadsAvailable={downloadsAvailable}
                name={sourceName}
                type="layer"
            />
        );
    }, [
        layerName,
        sourceName,
        downloadsAvailable,
        handleZoomToLayer,
        viewOn,
        dispatch,
    ]);

    const closeModal = useCallback(() => {
        setGeoDataTableActive(false);
    }, []);

    const toggleLegendPopup = useCallback(() => {
        if (legendPopup === null || layerName !== legendPopup.layerName) {
            dispatch(
                setLegendPopup({
                    sourceName,
                    layerName,
                }),
            );
            trackUserEventWithCurrentEvent({
                name: "layer_legend_pinned",
                payload: {
                    layer_name: layerName,
                    layer_id: id,
                },
            });
        } else {
            dispatch(setLegendPopup(null));
        }
    }, [
        legendPopup,
        layerName,
        dispatch,
        sourceName,
        trackUserEventWithCurrentEvent,
        id,
    ]);

    const renderTable = useCallback(
        (sourceName: string, layerName: string) => {
            return (
                <Modal onClose={closeModal} opened>
                    <CustomLayerTable
                        sourceName={sourceName}
                        layerName={layerName}
                    />
                </Modal>
            );
        },
        [closeModal],
    );

    const toggleLabel = useCallback(
        (labels: string[], clickedLabel: string): string[] => {
            if (labels.includes(clickedLabel)) {
                labels = labels.filter(
                    (existing: string) => existing !== clickedLabel,
                );
            } else {
                labels = [...labels, clickedLabel];
            }
            return labels;
        },
        [],
    );

    const filterClick = useCallback(
        (label: string, featureProperty: string) => {
            let layerFilter = layerFilters[sourceName];
            let appliedFilters: string[] = [];
            if (layerFilter && layerFilter.identifier === featureProperty) {
                appliedFilters = toggleLabel(layerFilter.values, label);
            } else {
                appliedFilters = [label];
            }
            dispatch(
                setLayerFilter({
                    sourceName: sourceName,
                    layerFilter: {
                        identifier: featureProperty,
                        values: appliedFilters,
                    },
                }),
            );
        },
        [layerFilters, sourceName, dispatch, toggleLabel],
    );

    const clearFilter = useCallback(() => {
        if (layerFilters[sourceName]) {
            dispatch(
                setLayerFilter({
                    sourceName: sourceName,
                    layerFilter: { identifier: null, values: [] },
                }),
            );
        }
    }, [dispatch, layerFilters, sourceName]);

    const complexLegend = complexPaintProperties.length !== 0;
    const selectedLayerName =
        highlightedLayer !== null ? highlightedLayer.layerName : null;
    const highlight = layerName === selectedLayerName;
    const layerFilter = layerFilters[sourceName];

    const simplifiedLayerName = layerName.toLowerCase().replace(" ", "-");

    return (
        <div
            id={id}
            className={cx(
                classes.LayerItem,
                { [classes.LegendOpen]: legendOpen },
                { [classes.Highlight]: highlight },
            )}
        >
            {geoDataTableActive && renderTable(sourceName, layerName)}
            <div className={classes.Header}>
                <div
                    className={cx(classes.ToggleTitle, {
                        [classes.ToggleTitleWithBadges]:
                            beta || tier !== "basic",
                    })}
                >
                    <Tooltip label={"Toggle Layer Visibility"}>
                        <span className={classes.VisibilityIcon} id={`report-layersTab-toggleVisibility-${simplifiedLayerName}`}>
                            <Toggle
                                heightRem={"1.5rem"}
                                active={visibility === "visible"}
                                onClick={toggleLayerVisibility}
                            />
                        </span>
                    </Tooltip>
                    <span className={classes.Label} id={`report-layersTab-label-${simplifiedLayerName}`}>
                        <ScrollableText text={layerName} />
                    </span>
                </div>

                <div className={classes.Icons}>
                    {(beta || tier !== "basic") && (
                        <Badges beta={beta} tier={tier} />
                    )}

                    {layerFilters[sourceName] && (
                        <Tooltip label={"Remove Filter"}>
                            <div
                                className={classes.LayerMenu}
                                onClick={clearFilter}
                            >
                                <Icon
                                    path={mdiFilterOff}
                                    color={getCssVar(
                                        layerFilters[sourceName]
                                            ? "--highlight-color"
                                            : "--secondary-color",
                                    )}
                                />
                            </div>
                        </Tooltip>
                    )}

                    {isCustomLayer && (
                        <Tooltip label={"Show in table"}>
                            <div
                                className={classes.LayerMenu}
                                onClick={() =>
                                    setGeoDataTableActive(!geoDataTableActive)
                                }
                            >
                                <Icon
                                    path={mdiTable}
                                    color={getCssVar(
                                        geoDataTableActive
                                            ? "--highlight-color"
                                            : "--text-color",
                                    )}
                                />
                            </div>
                        </Tooltip>
                    )}

                    <div
                        className={classes.LayerIcon}
                        onClick={
                            complexLegend
                                ? (e) => {
                                      setLegendOpen(!legendOpen);
                                      e.preventDefault();
                                  }
                                : undefined
                        }
                        id={`report-layersTab-legend-${simplifiedLayerName}`}
                    >
                        <LayerIcon
                            complexLegend={complexLegend}
                            paint={paint}
                            type={layerType}
                        />
                    </div>

                    <Popover
                        position={"top-end"}
                        classNames={{ dropdown: classes.ContextMenu }}
                    >
                        <Popover.Target>
                            <ActionIcon variant="subtle">
                                <span id={`report-layersTab-contextMenu-${simplifiedLayerName}`}>
                                    <Icon
                                        path={mdiDotsHorizontal}
                                        className={classes.ContextMenuIcon}
                                    />
                                </span>
                            </ActionIcon>
                        </Popover.Target>
                        <Popover.Dropdown>
                            {renderContextMenu()}
                        </Popover.Dropdown>
                    </Popover>
                </div>
            </div>
            {complexLegend && (
                <div className={cx(classes.LegendContainer)}>
                    <Legend
                        key={sourceName}
                        paint={paint}
                        layout={layout}
                        complexPaintProperties={complexPaintProperties}
                        type={layerType}
                        toggleLegendPopup={toggleLegendPopup}
                        layerName={layerName}
                        legendPopup={legendPopup!}
                        parent="layerListItem"
                        filterClick={filterClick}
                        isFiltered={layerFilters[sourceName]}
                        layerFilters={layerFilter}
                    />
                </div>
            )}
        </div>
    );
};

export default LayerListItem;
