import React, { FC, useEffect, useMemo } from "react";
import classes from "../Insights.module.css";
import { useViewportSize } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
    MRT_Header,
    MRT_Row,
    MRT_TableInstance,
    MantineReactTable,
    MRT_GlobalFilterTextInput as MRT_GLOBAL_FILTER_TEXT_INPUT,
    MRT_ShowHideColumnsButton as MRT_SHOW_HIDE_COLUMNS_BUTTON,
    MRT_TablePagination as MRT_TABLE_PAGINATION,
    useMantineReactTable,
} from "mantine-react-table";
import { Box, Flex, Loader, Skeleton } from "@mantine/core";
import Button from "../../../../../_Library/Button/Button";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { getCssVar } from "utils/CSSHelpers";
import {
    mdiDotsVertical,
    mdiDownload,
    mdiFilter,
    mdiFilterRemove,
    mdiMenuDown,
    mdiMenuSwap,
    mdiMenuUp,
} from "@mdi/js";
import Icon from "@mdi/react";
import { setDashboardView } from "store/report/reportActions";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { extractColumnNames } from "utils/MantineTable";
import { SetAssessmentFilters } from "store/insights/insightsActions";
import ReactTooltip from "react-tooltip";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { PerilType } from "store/system/systemTypes";
// required to prevent tooltips persisting on the mantine table
import "./mantine-override.modules.css";
import { IconSearch } from "@tabler/icons-react";
import { useDownloadPermission } from "hooks/authorization/useDownloadPermission";
import { useTableStateWithTracking } from "./useTableStateWithTracking";
import { bbox } from "@turf/turf";
import { useApiQuery } from "hooks/useAPI";

type InsightsTableProps = {
    loading: boolean;
    columns?: string[];
    data: Record<string, string | number>[];
    // filtered_ or raw_ will be prepended to the name
    exportSuffix: string;
    viewportHeightPct?: number;
    tableContainerMaxHeight?: string;
    beforeShowOnMap: (rows: MRT_Row[]) => GeoJSON.FeatureCollection;
    allowShowOnMap: boolean;
    formatData?: boolean;
};

const generateLoadingRows = () => {
    return Array.from({ length: 10 }, (_, i) => {
        return {
            accessorKey: `Loading ${i}`,
            header: `Column ${i + 1}`,
            Cell: () => {
                return <Skeleton visible width={"100%"} height={"100%"} />;
            },
            Header: ({ column }: MRT_Header) => (
                <span style={{ color: "gray" }}>{column.columnDef.header}</span>
            ),
        };
    });
};

export const InsightsTable: FC<InsightsTableProps> = ({
    loading,
    data,
    columns,
    exportSuffix,
    viewportHeightPct,
    tableContainerMaxHeight,
    beforeShowOnMap,
    allowShowOnMap = true,
    formatData = false,
}: InsightsTableProps) => {
    const { trackUserEventWithCurrentEvent } = useAnalytics();
    const viewport = useViewportSize();
    const height = viewport.height * ((viewportHeightPct || 100) / 100);
    const dispatch = useDispatch();
    const modalRef = useSelector(
        (state) => getStoreAtNamespaceKey(state, "ref").modalRef,
    );

    const peril = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").selectedPeril,
    );
    const { disable: disableDownloads, loading: permissionLoading } =
        useDownloadPermission();
    const mapRef = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "ref").mapRef,
    );
    const assessmentFilters = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").assessmentFilters,
    );

    const { data: exposureDamageClassifications } = useApiQuery<[string[]]>(
        `/events/layers/assessments/damage_classifications?peril_type=${peril}&assessment_type=exposure`,
        ["exposure_damage_classifications", peril!],
        {
            staleTime: Infinity,
        },
    );

    const { data: claimsDamageClassifications } = useApiQuery<[string[]]>(
        `/events/layers/assessments/damage_classifications?peril_type=${peril}&assessment_type=claims`,
        ["claims_damage_classifications", peril!],
        {
            staleTime: Infinity,
        },
    );

    const columnHeaders = useMemo(() => {
        return (data?.length && exposureDamageClassifications && claimsDamageClassifications)
            ? extractColumnNames(data, columns ?? [], formatData, exposureDamageClassifications[0] , claimsDamageClassifications[0]) //Always retrieve the first array, to ensure we use the latest version.
            : generateLoadingRows();
    }, [data, columns, formatData, exposureDamageClassifications, claimsDamageClassifications]);

    const dashboardView = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").dashboardView,
    );
    
    const insightsData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").insightsData,
    );

    const isPolicy = exportSuffix.includes("Policy");
    const source = modalRef.current && !isPolicy ? "map" : "insights";
    const type = isPolicy ? "policy" : "location";
    const { state, eventHandlers, setPaginationState } =
        useTableStateWithTracking({
            source,
            type,
            initialAssessmentFilters: assessmentFilters,
        });

    // Recalculate number of rows when the height changes
    useEffect(() => {
        let newRows = calculateNumberOfRows(height) || 10;
        setPaginationState((prev) => {
            return {
                ...prev,
                pageSize: newRows,
            };
        });
        // We want to trigger a re-render on dashboard view as well,
        // since switching from report to insights changes the number of rows
        // but does not calculate correctly - there may be a better way to avoid this
    }, [height, dashboardView, setPaginationState]);

    function calculateNumberOfRows(viewPortHeight: number): number {
        // Value of rem changes based on the font size of the root element
        const oneRem = parseFloat(
            getComputedStyle(document.documentElement).fontSize,
        );
        const insightsTopBarX: number =
            document
                .querySelector(`.${classes.InsightsTopBar}`)
                ?.getBoundingClientRect()!.bottom! || 0;
        const borderTable = 0.0625 * oneRem;
        const paddingTable = 1.5 * oneRem;
        const topBar = 30;
        const rowHeightInPixels = 30;
        const availableHeight =
            viewPortHeight -
            insightsTopBarX -
            borderTable -
            paddingTable -
            topBar -
            // Table header is a row in itself so needs to be subtracted
            60;
        const numberOfRows = Math.floor(availableHeight / rowHeightInPixels);
        if (numberOfRows < 3) {
            return 3;
        }

        return numberOfRows;
    }

    const renderTableActionBar = (table: MRT_TableInstance) => {
        return (
            <Box>
                <Flex justify={"space-between"} style={{ padding: "0.5rem" }}>
                    <Flex gap="xs" align="center">
                        <MRT_GLOBAL_FILTER_TEXT_INPUT table={table} />
                        <MRT_SHOW_HIDE_COLUMNS_BUTTON
                            onClick={() => {
                                trackUserEventWithCurrentEvent({
                                    name: "show_hide_columns_clicked",
                                });
                            }}
                            table={table}
                        />
                        <div className={classes.DownloadButtons}>
                            <div
                                data-for={"DownloadData"}
                                data-tip={
                                    disableDownloads
                                        ? "Access Restricted: You do not have permission to download Insights."
                                        : "Download Current View: Applies to data shown, including filters."
                                }
                            >
                                <Button
                                    size={{ width: "18rem", height: "3rem" }}
                                    onClick={() => {
                                        exportTableData(
                                            table.getPrePaginationRowModel()
                                                .rows,
                                        );
                                    }}
                                    disabled={disableDownloads}
                                >
                                    {permissionLoading ? (
                                        <Loader />
                                    ) : (
                                        <>
                                            <Icon
                                                path={mdiDownload}
                                                size={1.2}
                                            />
                                            Download Data
                                        </>
                                    )}
                                </Button>
                                <ReactTooltip
                                    id={"DownloadData"}
                                    place={"top"}
                                    effect={"float"}
                                />
                            </div>

                            {allowShowOnMap && (
                                <Button
                                    size={{ width: "18rem", height: "3rem" }}
                                    onClick={() => {
                                        trackUserEventWithCurrentEvent({
                                            name: "show_on_map_clicked",
                                            payload: {
                                                source,
                                                type,
                                            },
                                        });

                                        const filteredGeojson = beforeShowOnMap(
                                            table.getPrePaginationRowModel()
                                                .rows,
                                        );
                                        dispatch(
                                            setDashboardView({
                                                view: "report",
                                            }),
                                        );

                                        const bBox = bbox(filteredGeojson) as [
                                            number,
                                            number,
                                            number,
                                            number,
                                        ];
                                        mapRef?.current?.fitBounds(bBox, {
                                            padding: 50,
                                        });
                                    }}
                                    disabled={
                                        !table.getFilteredRowModel().rows.length
                                    }
                                >
                                    Show on map
                                </Button>
                            )}
                        </div>
                    </Flex>
                    <Flex align={"center"} gap={"sm"}>
                        {table.getFilteredRowModel().rows.length !==
                            data.length && (
                            <div
                                data-tip={"Reset all filters"}
                                data-for={"ResetAllFilters"}
                            >
                                <Button
                                    size={{ width: "3rem", height: "3rem" }}
                                    onClick={() => {
                                        table.resetColumnFilters();
                                        dispatch(
                                            SetAssessmentFilters({
                                                assessmentType: "claims",
                                                assessmentFilter: null,
                                            }),
                                        );
                                        dispatch(
                                            SetAssessmentFilters({
                                                assessmentType: "exposure",
                                                assessmentFilter: null,
                                            }),
                                        );
                                    }}
                                >
                                    <Icon path={mdiFilterRemove} size={1.5} />
                                </Button>
                                <ReactTooltip
                                    id={"ResetAllFilters"}
                                    place={"top"}
                                    effect={"solid"}
                                />
                            </div>
                        )}
                        {insightsData?.description.as_at_date &&
                            `As At Date: ${insightsData?.description?.as_at_date} | `}
                        Exposure Layer Version:{" "}
                        {insightsData?.description?.exposure_revision} | Claims
                        Layer Version:{" "}
                        {insightsData?.description?.claims_revision}
                    </Flex>
                </Flex>
            </Box>
        );
    };

    const renderTableBottomBar = (table: MRT_TableInstance) => {
        return (
            <Flex justify={"center"}>
                <MRT_TABLE_PAGINATION table={table} />
            </Flex>
        );
    };

    // Download Functionality
    const csvConfig = mkConfig({
        fieldSeparator: ",",
        decimalSeparator: ".",
        useKeysAsHeaders: true,
        filename: exportSuffix,
    });

    const exportTableData = (rows: MRT_Row[]) => {
        const segments = exportSuffix.split("_");
        segments.splice(segments.length - 3, 1);

        trackUserEventWithCurrentEvent({
            name: "insights_download_data_clicked",
            payload: {
                source,
                type,
                portfolio: new URLSearchParams(window.location.search).get(
                    "portfolio",
                )!,
                peril: peril as PerilType,
            },
        });

        const rowData = rows.map((row: MRT_Row) => {
            if ("MIS_ContractID" in row.original) {
                const { MIS_ContractID, ...rest } = row.original;
                return rest;
            }
            return row.original;
        });

        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    const table = useMantineReactTable({
        columns: columnHeaders,
        data,
        enableDensityToggle: false,
        ...eventHandlers,
        enableFullScreenToggle: false,
        enableColumnDragging: false,
        enableClickToCopy: true,
        enableColumnResizing: true,
        enableHiding: true,
        enablePinning: true,
        enableFilters: true,
        enableColumnActions: true,
        enableColumnFilterModes: true,
        enableColumnFilters: true,
        enableSorting: true,
        enableTopToolbar: true,
        enablePagination: true,
        globalFilterFn: "contains",
        columnFilterDisplayMode: "popover",
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            ...state,
        },
        state: {
            showSkeletons: loading,
            ...state,
        },
        mantineFilterTextInputProps: {
            styles: {
                input: {
                    backgroundColor: "var(--secondary-color)",
                    "&::placeholder": {
                        color: "var(--text-color-lo-cont)",
                    },
                },
            },
        },
        mantineTableContainerProps: {
            sx: {
                maxHeight: tableContainerMaxHeight || "100%",
            },
        },
        mantineTableProps: {
            verticalSpacing: "0.2rem",
        },
        mantineSearchTextInputProps: {
            sx: {
                marginRight: "1rem",
            },
            styles: {
                input: {
                    backgroundColor: "var(--secondary-color-lo-cont)",
                    "&::placeholder": {
                        color: "var(--text-color-lo-cont)",
                    },
                },
                wrapper: {
                    borderRadius: "var(--border-radius-md)",
                    backgroundColor: "var(--secondary-color-lo-cont)",
                },
            },
        },
        icons: {
            IconDotsVertical: () => (
                <Icon
                    path={mdiDotsVertical}
                    style={{
                        transform: "scale(1.25)",
                    }}
                />
            ),
            IconSortDescending: () => (
                <Icon
                    path={mdiMenuDown}
                    style={{
                        transform: "scale(1.75)",
                    }}
                />
            ),
            IconSortAscending: () => (
                <Icon
                    path={mdiMenuUp}
                    style={{
                        transform: "scale(1.75)",
                    }}
                />
            ),
            IconArrowsSort: () => (
                <Icon
                    path={mdiMenuSwap}
                    style={{
                        transform: "scale(1.75)",
                    }}
                />
            ),
            IconSearch: () => (
                <IconSearch
                    style={{
                        transform: "scale(0.75)",
                    }}
                />
            ),
            IconFilter: () => (
                <Icon
                    path={mdiFilter}
                    style={{
                        transform: "scale(1.5)",
                    }}
                />
            ),
        },
        mantineTableBodyRowProps: {
            sx: {
                ":hover": {
                    opacity: 0.75,
                },
            },
        },
        mantineCopyButtonProps: {
            sx: {
                ":hover": {
                    backgroundColor: "var(--highlight-color-50)",
                },
                ":active": {
                    backgroundColor: "var(--highlight-color-75)",
                },
            },
        },
        mantinePaginationProps: {
            showRowsPerPage: false,
        },
        mantineTableBodyCellProps: {
            sx: {
                textWrap: "nowrap",
                border: "none",
                backgroundColor: "var(--secondary-color-lo-cont)",
            },
            style: {
                maxWidth: "max-content",
            },
        },
        mantineTopToolbarProps: {
            sx: {
                borderBottom: `0.1rem solid ${getCssVar("--border-color")}`,
            },
        },
        mantineTableHeadProps: {
            sx: {
                padding: "1rem",
            },
        },
        mantinePaperProps: {
            sx: {
                border: "none",
            },
        },
        renderTopToolbar: ({ table }) => renderTableActionBar(table),
        renderBottomToolbar: ({ table }) => renderTableBottomBar(table),
    });

    return (
        <div className={classes.InsightsTable}>
            <MantineReactTable
                key={
                    assessmentFilters.claims.toString() +
                    assessmentFilters.exposure.toString()
                }
                table={table}
            />
        </div>
    );
};
