import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "../AccountModal.module.css";
import {
    Box,
    Button,
    Card,
    Flex,
    Group,
    LoadingOverlay,
    ScrollArea,
    Stack,
    Text,
} from "@mantine/core";
import { AccessManagementUserBase } from "types/auth";
import { useUpdateUserNotificationsMutation } from "crud/hooks/users";
import {
    NotificationEventTypes,
    NotificationRegions,
    NotificationRegion,
    NotificationEventType,
} from "store/system/systemTypes";
import ControlSection from "../../../../_Library/ControlSection/ControlSection";
import Toggle from "components/_Library/Inputs/Toggle/Toggle";
import { useUserMeQuery } from "crud/hooks/me";
import _ from "lodash";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { cx } from "@emotion/css";

interface OwnProps {
    user: AccessManagementUserBase;
}

type NotificationsTabProps = OwnProps;

enum NotificationOption {
    marketing_emails,
    event_notifications,
}

export type NotificationOptions = keyof typeof NotificationOption;
export type NotificationSettings = { [key in NotificationOptions]: boolean };

const NotificationsTab: React.FC<NotificationsTabProps> = () => {
    const { refetch, isLoading, data: user } = useUserMeQuery();
    const [marketingEmails, setMarketingEmails] = useState<boolean>(
        user?.notifications.marketing_emails!,
    );
    const { trackUserEvent } = useAnalytics();
    const [eventUpdateNotifications, setEventUpdateNotifications] =
        useState<boolean>(user?.notifications.event_update_notifications!);
    const [selectedRegions, setSelectedRegions] = useState<
        NotificationRegion[]
    >(user?.notifications.regions || NotificationRegions);
    const [selectedEventTypes, setSelectedEventTypes] = useState<
        NotificationEventType[]
    >(user?.notifications.event_types || NotificationEventTypes);
    const [eventNotifications, setEventNotifications] = useState<boolean>(
        (user?.notifications?.event_types! &&
            user?.notifications?.event_types?.length! > 0) ||
            (user?.notifications?.regions! &&
                user?.notifications?.regions?.length! > 0) ||
            user?.notifications?.event_update_notifications!,
    );

    const setSelectedEventTypesHandler = (
        eventTypes: NotificationEventType[],
    ) => {
        setSelectedEventTypes(eventTypes);
    };

    const setSelectedRegionsHandler = (regions: NotificationRegion[]) => {
        setSelectedRegions(regions);
    };

    const eventNotificationsChanged =
        !_.isEqual(
            new Set(selectedEventTypes),
            new Set(user!.notifications.event_types),
        ) ||
        !_.isEqual(
            new Set(selectedRegions),
            new Set(user!.notifications.regions),
        );
    // Ensure that when regions and event types change,
    // the event notifications are turned on or off
    // depending on whether any are selected
    useEffect(() => {
        if (eventNotificationsChanged) {
            const eventNotificationsValid =
                (selectedEventTypes.length > 0 ||
                selectedRegions.length > 0)
            setEventNotifications(eventNotificationsValid);
            if (!eventNotificationsValid) {
                setEventUpdateNotifications(false);
            }
        }
    }, [eventNotificationsChanged, selectedEventTypes, selectedRegions]);


    const eventUpdatesChanged =
        user?.notifications.event_update_notifications !==
        eventUpdateNotifications;
    const marketingEmailsChanged =
        user?.notifications.marketing_emails !== marketingEmails;
    const anyUnsavedChanges = useMemo(() => {
        return (
            eventNotificationsChanged ||
            eventUpdatesChanged ||
            marketingEmailsChanged
        );
    }, [
        eventNotificationsChanged,
        eventUpdatesChanged,
        marketingEmailsChanged,
    ]);

    const trackEvents = () => {
        if (eventNotificationsChanged) {
            trackUserEvent({
                name: "event_notification_changes_saved",
                payload: { enabled: eventNotifications },
            });
        }
        if (eventUpdatesChanged) {
            trackUserEvent({
                name: "event_updates_change_saved",
                payload: { enabled: eventUpdateNotifications },
            });
        }
        if (marketingEmailsChanged) {
            trackUserEvent({
                name: "marketing_emails_change_saved",
                payload: { enabled: marketingEmails },
            });
        }
    };

    const createUpdateUserPreferencesMutation =
        useUpdateUserNotificationsMutation({
            onSuccess: () => {
                refetch();
                trackEvents();
            },
        });

    const updateNotificationSettings = () => {
        const newNotificationSettings = {
            marketing_emails: marketingEmails,
            regions: selectedRegions,
            event_types: selectedEventTypes,
            event_update_notifications: eventUpdateNotifications,
        };
        createUpdateUserPreferencesMutation.mutate(
            newNotificationSettings,
        );
    };

    const handlePerilIndicatorClick = useCallback(() => {
        if (selectedEventTypes.length <= 0) {
            setSelectedEventTypesHandler(NotificationEventTypes);
        } else {
            setSelectedEventTypesHandler([]);
        }
    }, [selectedEventTypes]);

    const handleRegionIndicatorClick = useCallback(() => {
        if (selectedRegions.length <= 0) {
            setSelectedRegionsHandler(NotificationRegions);
        } else {
            setSelectedRegionsHandler([]);
        }
    }, [selectedRegions]);

    const cancelHandler = () => {
        // Reset all to default from user
        setSelectedEventTypes(user!.notifications.event_types);
        setSelectedRegions(user!.notifications.regions);
        setEventUpdateNotifications(
            user!.notifications.event_update_notifications,
        );
        setMarketingEmails(user!.notifications.marketing_emails);
    };

    const renderSaveAndIndicator = () => {
        if (!anyUnsavedChanges) {
            return null;
        }

        return (
            <Box className={classes.unsavedChangesContainer}>
                <Group align="center" position="apart" p="sm">
                    <Text size="md" c="gray">
                        You have unsaved changes
                    </Text>
                    <Group>
                        <Button
                            variant="cancel"
                            size="md"
                            onClick={cancelHandler}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="confirm"
                            size="md"
                            onClick={updateNotificationSettings}
                        >
                            Save
                        </Button>
                    </Group>
                </Group>
            </Box>
        );
    };

    return (
        <>
            <ScrollArea.Autosize type="auto" h="53.5rem" offsetScrollbars>
                <LoadingOverlay visible={isLoading} overlayBlur={2} />
                <Box className={cx(classes.ContentContainer, classes.noWrap)}>
                    <Stack>
                        <b>Email Notifications</b>
                        <Box className={classes.NotificationSettingsContainer}>
                            <Stack spacing="lg">
                                <Stack spacing="0">
                                    <p className={classes.Subheader}>
                                        Service Updates (Always On)
                                    </p>
                                    <Card
                                        bg="var(--primary-color)"
                                        mt="sm"
                                        px="sm"
                                    >
                                        <Text
                                            size="xs"
                                            w="80%"
                                            className={classes.wrap}
                                        >
                                            Keep up-to-date with essential
                                            information about app performance,
                                            maintenance, and system
                                            improvements.
                                        </Text>
                                    </Card>
                                </Stack>

                                <Stack>
                                    <Group
                                        align="center"
                                        w="100%"
                                        position="apart"
                                        pr="lg"
                                    >
                                        <p className={classes.Subheader}>
                                            Marketing Updates
                                        </p>
                                        <Toggle
                                            onClick={() => {
                                                setMarketingEmails(
                                                    (prev) => !prev,
                                                );
                                            }}
                                            active={marketingEmails}
                                        />
                                    </Group>
                                    <Card bg="var(--primary-color)">
                                        <Flex
                                            justify="space-between"
                                            align="center"
                                        >
                                            <Text
                                                size="xs"
                                                w="80%"
                                                className={classes.wrap}
                                            >
                                                Receive marketing communications
                                                that keep you informed about the
                                                latest news, promotions, and
                                                updates from McKenzie
                                                Intelligence Services.
                                            </Text>
                                        </Flex>
                                    </Card>
                                </Stack>

                                <Stack>
                                    <Group
                                        align="center"
                                        w="100%"
                                        position="apart"
                                        pr="lg"
                                    >
                                        <p className={classes.Subheader}>
                                            Event Notifications
                                        </p>
                                        <Toggle
                                            onClick={() => {
                                                if (eventNotifications) {
                                                    setSelectedEventTypes([]);
                                                    setSelectedRegions([]);
                                                    setEventUpdateNotifications(
                                                        false,
                                                    );
                                                } else {
                                                    setSelectedEventTypes(
                                                        NotificationEventTypes,
                                                    );
                                                    setSelectedRegions(
                                                        NotificationRegions,
                                                    );
                                                    setEventUpdateNotifications(
                                                        true,
                                                    );
                                                }
                                                setEventNotifications(
                                                    (prev) => !prev,
                                                );
                                            }}
                                            active={eventNotifications}
                                        />
                                    </Group>
                                    <Stack spacing="sm">
                                        <Card
                                            bg="var(--primary-color)"
                                            px="lg"
                                            pb="md"
                                            h="100%"
                                        >
                                            <Group
                                                align="center"
                                                w="100%"
                                                position="apart"
                                                pb="md"
                                            >
                                                <Text
                                                    size="xs"
                                                    maw="80%"
                                                    className={classes.wrap}
                                                >
                                                    Stay informed for new events
                                                    MIS are responding to.
                                                    Customise your notifications
                                                    below:
                                                </Text>
                                            </Group>
                                            <Group noWrap>
                                                <ControlSection
                                                    title="Event Types"
                                                    items={
                                                        NotificationEventTypes
                                                    }
                                                    selectedItems={
                                                        selectedEventTypes
                                                    }
                                                    onIndicatorClick={
                                                        handlePerilIndicatorClick
                                                    }
                                                    onChange={
                                                        setSelectedEventTypesHandler
                                                    }
                                                    cols={3}
                                                    width="50%"
                                                />
                                                <ControlSection
                                                    title="Regions"
                                                    items={NotificationRegions}
                                                    selectedItems={
                                                        selectedRegions
                                                    }
                                                    onIndicatorClick={
                                                        handleRegionIndicatorClick
                                                    }
                                                    onChange={
                                                        setSelectedRegionsHandler
                                                    }
                                                    cols={1}
                                                    width="50%"
                                                />
                                            </Group>
                                            <Group
                                                align="center"
                                                w="100%"
                                                position="apart"
                                            >
                                                <Text size="xs" maw="80%">
                                                    Notify me when events are
                                                    updated with more details,
                                                    including imagery.
                                                </Text>
                                                <Toggle
                                                    disabled={
                                                        selectedRegions.length ===
                                                            0 &&
                                                        selectedEventTypes.length ===
                                                            0
                                                    }
                                                    onClick={() => {
                                                        setEventUpdateNotifications(
                                                            (prev) => !prev,
                                                        );
                                                    }}
                                                    active={
                                                        eventUpdateNotifications
                                                    }
                                                />
                                            </Group>
                                        </Card>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </ScrollArea.Autosize>
            {renderSaveAndIndicator()}
        </>
    );
};

export default NotificationsTab;
