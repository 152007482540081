import React, { FC, ReactElement, useEffect, useState } from "react";

import classes from "./ToolsControl.module.css";
import dropdownClasses from "components/_Library/Dropdown/ActionDropdown.module.css";
import Icon from "@mdi/react";
import { mdiSquareOutline, mdiTapeMeasure, mdiVectorRectangle } from "@mdi/js";
import { cx } from "@emotion/css";
import ActionDropdown from "components/_Library/Dropdown/ActionDropdown";
import { setInteractionMode, setMapType } from "store/report/reportActions";
import { useDispatch, useSelector } from "react-redux";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { RootState } from "store/store";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";

import LayoutSlider from "assets/icons/layouts/LayoutSlider.svg?react";
import LayoutDual from "assets/icons/layouts/LayoutDual.svg?react";
import { Tooltip } from "@mantine/core";

const ToolsControl: FC = () => {
    const dispatch = useDispatch();
    const analytics = useAnalytics();

    const [expandedControlIndex, setExpandedControlIndex] = useState(-1);

    const currentInteractionMode = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").interactionMode,
    );

    const interactionMode = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").interactionMode,
    );

    const layersConfig = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").layersConfig,
    );

    // Helper functions
    const setControlIndex = (index: number) => {
        setExpandedControlIndex((prev) => (prev === index ? -1 : index));
    };

    const controlIndexIsActive = (index: number) => {
        return expandedControlIndex === index;
    };

    useEffect(() => {
        setExpandedControlIndex(-1);
    }, [interactionMode]);

    const renderCurrentLayoutIcon = (): {
        renderElementFn: () => ReactElement;
    } => {
        switch (layersConfig.mapType) {
            case "single":
                return {
                    renderElementFn: () => (
                        <span onClick={() => setMapType("single")}>
                            <Icon
                                path={mdiSquareOutline}
                                size={1.5}
                                className={dropdownClasses.ToolBarIcon}
                            />
                        </span>
                    ),
                };
            case "dual":
                return {
                    renderElementFn: () => (
                        <LayoutDual
                            className={dropdownClasses.ToolBarIcon}
                            onClick={() => setMapType("dual")}
                        />
                    ),
                };
            case "compare":
                return {
                    renderElementFn: () => (
                        <LayoutSlider
                            className={dropdownClasses.ToolBarIcon}
                            onClick={() => setMapType("compare")}
                        />
                    ),
                };
        }
    };

    const toggleMapInteraction = (interactionMode: string) => {
        if (interactionMode === currentInteractionMode) {
            dispatch(setInteractionMode("standard"));
            setControlIndex(-1);
        } else {
            if (interactionMode === "measure-distance") {
                setControlIndex(0);
                dispatch(setInteractionMode("measure-distance"));
                analytics.trackUserEventWithCurrentEvent({
                    name: "measure_tool_clicked",
                    payload: {
                        type: "measure_distance",
                        status: "start",
                    },
                });
            } else if (interactionMode === "measure-area") {
                setControlIndex(1);
                dispatch(setInteractionMode("measure-area"));
                analytics.trackUserEventWithCurrentEvent({
                    name: "measure_tool_clicked",
                    payload: {
                        type: "measure_area",
                        status: "start",
                    },
                });
            } else {
                setControlIndex(-1);
            }
        }
    };

    return (
        <div
            className={cx(classes.DropdownListContainer)}
            id={"tourid_Toolbar"}
            style={{
                zIndex: 3,
            }}
        >
            <div
                className={dropdownClasses.ActionDropdownContainer}
                id="map-tool-measureDistance"
            >
                <Tooltip label="Measure Distance" position="right">
                    <span
                        onClick={() => {
                            toggleMapInteraction("measure-distance");
                        }}
                    >
                        <Icon
                            className={
                                controlIndexIsActive(0)
                                    ? dropdownClasses.Highlighted
                                    : dropdownClasses.ToolBarIcon
                            }
                            path={mdiTapeMeasure}
                            size={1.5}
                        />
                    </span>
                </Tooltip>
            </div>
            
            <div
                className={dropdownClasses.ActionDropdownContainer}
                id="map-tool-measureArea"
            >
                <Tooltip label="Measure Area" position="right">
                    <span
                        onClick={() => {
                            toggleMapInteraction("measure-area");
                        }}
                    >
                        <Icon
                            className={
                                controlIndexIsActive(1)
                                    ? dropdownClasses.Highlighted
                                    : dropdownClasses.ToolBarIcon
                            }
                            path={mdiVectorRectangle}
                            size={1.5}
                        />
                    </span>
                </Tooltip>
            
            </div>
            <ActionDropdown
                containerClassName={
                    interactionMode !== "standard"
                        ? dropdownClasses.Hidden
                        : dropdownClasses.ActionDropdownContainer
                }
                expanded={controlIndexIsActive(2)}
                expanderButtonProps={{
                    ...renderCurrentLayoutIcon(),
                    onClick: () => {
                        setControlIndex(2);
                        analytics.trackUserEventWithCurrentEvent({
                            name: "map_layout_clicked",
                        });
                    },
                    tooltip: "Map Layout",
                    tooltipPlacement: "right",
                    className: controlIndexIsActive(2)
                        ? dropdownClasses.Highlighted
                        : dropdownClasses.ToolBarIcon,
                    id: "map-tool-mapLayout",
                }}
                innerButtonProps={[
                    {
                        renderElementFn: () => (
                            <span
                                onClick={() => {
                                    dispatch(setMapType("single"));
                                    analytics.trackUserEventWithCurrentEvent({
                                        name: "map_layout_changed",
                                        payload: {
                                            type: "single",
                                        },
                                    });
                                }}
                            >
                                <Icon path={mdiSquareOutline} size={1.5} />
                            </span>
                        ),
                        className:
                            layersConfig.mapType === "single"
                                ? dropdownClasses.Highlighted
                                : dropdownClasses.ToolBarIcon,
                        subHeading: "Single",
                        id: "map-mapLayout-single",
                    },
                    {
                        renderElementFn: () => (
                            <LayoutDual
                                onClick={() => {
                                    dispatch(setMapType("dual"));
                                    analytics.trackUserEventWithCurrentEvent({
                                        name: "map_layout_changed",
                                        payload: {
                                            type: "dual",
                                        },
                                    });
                                }}
                            />
                        ),
                        className:
                            layersConfig.mapType === "dual"
                                ? dropdownClasses.Highlighted
                                : dropdownClasses.ToolBarIcon,
                        subHeading: "Dual",
                        id: "map-mapLayout-dual",
                    },
                    {
                        renderElementFn: () => (
                            <LayoutSlider
                                onClick={() => {
                                    dispatch(setMapType("compare"));
                                    analytics.trackUserEventWithCurrentEvent({
                                        name: "map_layout_changed",
                                        payload: {
                                            type: "slider",
                                        },
                                    });
                                }}
                            />
                        ),
                        className:
                            layersConfig.mapType === "compare"
                                ? dropdownClasses.Highlighted
                                : dropdownClasses.ToolBarIcon,
                        subHeading: "Slider",
                        id: "map-mapLayout-slider",
                    },
                ]}
            />
        </div>
    );
};

export default ToolsControl;
