import React, { FC } from "react";
import classes from "./StatBar.module.css";
import {
    DamageAssessment,
    DamageAssessmentColors,
    getTextColor,
} from "utils/Colors";
import cx from "classnames";
import { InsightsPieSchema } from "crud/insightsCRUD";
import { Skeleton, Tooltip } from "@mantine/core";
import { mdiFilterRemove, mdiHelpCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useDispatch, useSelector } from "react-redux";
import { SetAssessmentFilters } from "store/insights/insightsActions";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { extractCatToPaintTuples } from "utils/PaintHelpers";
import _ from "lodash";

interface OwnProps {
    countLabel?: string;
    suffix?: string;
    barKeyOrientation: "landscape" | "portrait";
    data: InsightsPieSchema[] | null;
    displayKey?: boolean;
    tooltip?: string;
    applyToAssessment: "exposure" | "claims";
    canFilterInsights: boolean;
    onClick: (label: string) => void;
}

const StatBar: FC<OwnProps> = (props) => {
    const renderNoData = () => {
        return (
            <div
                className={classes.StatisticNoneToShow}
                style={{
                    width: `100%`,
                    height: "100%",
                }}
            >
                <p className={classes.NoDataText}>
                    No {props.countLabel} to show
                </p>
            </div>
        );
    };

    const dispatch = useDispatch();

    let assessmentFilters = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").assessmentFilters,
    );

    let insightsStyles = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").insightsStyles,
    );

    let filteredLayerData = props.canFilterInsights
        ? assessmentFilters[props.applyToAssessment]
        : null;

    const renderStats = () => {
        if (!insightsStyles || !props.data || !insightsStyles) return null;
        let paintTuples: [string, string][] = [];

        if (props.applyToAssessment === "exposure") {
            if (!(_.get(insightsStyles, "exposure")?.style?.paint)) return null;
            paintTuples = paintTuples.concat(
                extractCatToPaintTuples(insightsStyles["exposure"]),
            );
        }
        if (props.applyToAssessment === "claims") {
            if (!(_.get(insightsStyles, "claims")?.style?.paint)) return null;
            paintTuples = paintTuples.concat(
                extractCatToPaintTuples(insightsStyles["claims"]),
            );
        }

        const total = getTotalCount();

        return paintTuples.map(([category, color]) => {
            const statistic = props.data!.find(
                (datum) => datum.label === category,
            );

            if (statistic) {
                let width = (statistic.value / total) * 100;
                let label = statistic.label;
                let isFilteredData =
                    !filteredLayerData?.includes(statistic.label) &&
                    statistic.label &&
                    filteredLayerData?.length !== 0;

                return (
                    <Tooltip 
                        label={`${label}: ${statistic.value} (${width.toFixed(2)}%)`}
                        position={"bottom"}
                    >
                        <div
                            className={cx(
                                classes.Statistic,
                                {
                                    [classes.BarChartFilteringStyles]:
                                        props.canFilterInsights,
                                },
                                {
                                    [classes.NotSelected]: props.canFilterInsights
                                        ? isFilteredData
                                        : false,
                                },
                            )}
                            key={statistic.label}
                            style={{
                                width: `max(${width}%, 0.5rem)`,
                                background: color,
                            }}
                            onClick={() => {
                                if (props.canFilterInsights) {
                                    dispatch(
                                        SetAssessmentFilters({
                                            assessmentType: props.applyToAssessment,
                                            assessmentFilter: statistic.label,
                                        }),
                                    );
                                    props.onClick(statistic.label);
                                }
                            }}
                        >
                            <div className={classes.StatLabel}>
                                <div style={{ color: getTextColor(color) }}>
                                    {label}: {statistic.value}
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                );
            } else {
                return null;
            }
        });
    };

    const renderLegend = () => {
        return props.data!.map((statistic) => {
            let color =
                DamageAssessmentColors[statistic.label as DamageAssessment];
            return (
                <Tooltip label={statistic.label}>
                    <div
                        key={statistic.label}
                        className={classes.LegendIcon}
                        style={{
                            background: color,
                        }}
                    ></div>
                </Tooltip>
            );
        });
    };

    const getTotalCount = () => {
        return props.data!.reduce((sum, stat) => sum + stat.value, 0);
    };

    const renderStatsOrNoData = () => {
        const hasStatistics = getTotalCount();
        return hasStatistics ? renderStats() : renderNoData();
    };

    const renderLegendOrNoData = () =>
        props.data!.length ? renderLegend() : null;

    const renderKeyOrNoData = () => (props.data!.length ? "Key" : null);

    const displayKey = () => {
        return (
            <div className={classes.LegendContainer}>
                <p className={classes.Text}>
                    {props.data ? (
                        renderKeyOrNoData()
                    ) : (
                        <Skeleton width={"5rem"} height={"1.4rem"} />
                    )}
                </p>
                <div className={classes.Legend}>
                    {props.data ? (
                        renderLegendOrNoData()
                    ) : (
                        <Skeleton width={"30%"} height={"1.4rem"} />
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className={classes.Container}>
            {props.countLabel && props.data ? (
                <div className={classes.StatTitle}>
                    <p className={classes.Title}>
                        {props.countLabel}
                        {getTotalCount()
                            ? `: ${getTotalCount()} ${props.suffix}`
                            : null}
                    </p>
                    <div className={classes.Icons}>
                        {props.canFilterInsights &&
                        filteredLayerData?.length ? (
                            <Tooltip label="Remove Filters">
                                <div
                                    className={classes.ResetFilter}
                                    onClick={() => {
                                        dispatch(
                                            SetAssessmentFilters({
                                                assessmentType:
                                                    props.applyToAssessment,
                                                assessmentFilter: null,
                                            }),
                                        );
                                    }}
                                >
                                    <Icon path={mdiFilterRemove} size={1} />
                                </div>
                            </Tooltip>
                            
                        ) : null}
                        <Tooltip label={props.tooltip ?? ""} position={"left"} multiline width={300}>   
                            <Icon
                                path={mdiHelpCircleOutline}
                                size={1}
                            />
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <p className={classes.Title}>
                    <Skeleton width={"20rem"} height={"1.5rem"} />
                </p>
            )}

            <div
                className={cx(classes.InnerContainer, {
                    [classes.Landscape]:
                        props.barKeyOrientation === "landscape",
                })}
            >
                <div className={classes.BarContainer}>
                    {props.data ? (
                        renderStatsOrNoData()
                    ) : (
                        <Skeleton height={"3rem"} />
                    )}
                </div>
                {props.displayKey && displayKey()}
            </div>
        </div>
    );
};

export default StatBar;
