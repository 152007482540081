import React from 'react'
import classes from "./Onboarding.module.css"
import { Link, useHistory } from 'react-router-dom'
import { motion } from "framer-motion"
import Icon from '@mdi/react'
import { mdiArrowDownCircleOutline, mdiArrowUpCircleOutline } from '@mdi/js'
import { Select, TextInput } from '@mantine/core'
import { ROLE_DIVISIONS } from 'store/user/utils'
import { UseFormReturnType } from '@mantine/form'
import { FormValues } from './Onboarding'

interface JobRolesProps {
    form: UseFormReturnType<FormValues>
    updateLocalStorage: () => void
}

const JobRoles: React.FC<JobRolesProps> = ({form, updateLocalStorage}) => {

    const history = useHistory()
    
    const handleNextStep = () => {
        const validationResult = form.validate()
        const validityChecks = !['role_division'].some(key => key in validationResult.errors);
        const validToProceed = validityChecks || !["Insurance", "Reinsurance"].includes(form.getInputProps('industry').value);

        if (validToProceed) {
            updateLocalStorage()
            history.push("/onboarding/4") 
        }    
    }

    return (
        <motion.div 
            className={classes.Container}
            initial={{
                y: "50vh",
                opacity: 0,
            }}
            animate={{
                y: "0",
                opacity: 1,
                transitionDuration: "0.5s"
            }}
            exit={{
                y: "-50vh",
                opacity: 0,
            }}
        >
            <div className={classes.ContinueContainer} onClick={updateLocalStorage}>
                <Link to={["Insurance", "Reinsurance"].includes(form.getInputProps('industry').value) ? "/onboarding/2" : "/onboarding/1"} className={classes.PreviousButton}>
                    <Icon className={classes.PreviousIcons} path={mdiArrowUpCircleOutline} size={2}/>
                </Link>   
            </div>

            <div className={classes.FormContainer}>
                {
                    ["Insurance", "Reinsurance"].includes(form.getInputProps('industry').value) &&
                        <Select 
                            label="Role Division"
                            required 
                            data={ROLE_DIVISIONS} 
                            variant={"primaryBg"}
                            maxDropdownHeight={450}
                            {...form.getInputProps('role_division')}
                        />
                }
                <TextInput 
                    label="Job Role (Optional)" 
                    variant={"primaryBg"}
                    {...form.getInputProps('job_role')}
                />
            </div>
            
            <div className={classes.ContinueContainer} onClick={handleNextStep}>
                <Icon className={classes.ContinueIcons} path={mdiArrowDownCircleOutline} size={5}/>
            </div>
        </motion.div>
    )
}

export default JobRoles