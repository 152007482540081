import React from 'react'
import { Link } from 'react-router-dom'
import classes from "./Onboarding.module.css"
import { motion } from "framer-motion"
import Button from 'components/_Library/Button/Button'
import { Select } from '@mantine/core'
import Icon from '@mdi/react'
import { mdiArrowUpCircleOutline } from '@mdi/js'
import { NotificationRegions } from 'store/system/systemTypes'
import { FormValues } from './Onboarding'
import { UseFormReturnType } from '@mantine/form'

interface RegionProps {
    form: UseFormReturnType<FormValues>
    updateLocalStorage: () => void
    submitForm: () => void
}

const Region: React.FC<RegionProps> = ({form, submitForm, updateLocalStorage}) => {

    return (
        <motion.div 
            className={classes.Container}
            initial={{
                y: "50vh",
                opacity: 0,
            }}
            animate={{
                y: "0",
                opacity: 1,
                transitionDuration: "0.5s"
            }}
            exit={{
                y: "-50vh",
                opacity: 0,
            }}
        >
            <div className={classes.ContinueContainer} onClick={updateLocalStorage}>
                <Link to="3">
                    <Icon className={classes.PreviousIcons} path={mdiArrowUpCircleOutline} size={2}/>
                </Link>   
            </div>

            <div className={classes.FormContainer}>
                <Select 
                    data={NotificationRegions} 
                    label="Region You're Based In (Optional)" 
                    variant={"primaryBg"} 
                    maxDropdownHeight={450}
                    {...form.getInputProps('region')}
                />
            </div>
            
            <div className={classes.ContinueContainer}>
                <Button 
                    type={"positive"} 
                    highlightBackground 
                    size={{width: "15rem"}}
                    onClick={() => {
                        submitForm()
                    }}
                >
                    Enter GEO
                </Button>
            </div>
        </motion.div>
    )
}

export default Region