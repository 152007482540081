export const LINES_OF_BUSINESS = [
    "Aviation",
    "Construction",
    "Energy",
    "Fine Art",
    "Specie",
    "Marine",
    "Political Violence",
    "Property Direct and Facultative",
    "Property Binders",
    "Property Treaty",
    "Reinsurance",
    "Other"
]

export const ROLE_DIVISIONS = [
    "Claims",
    "Data & Analytics",
    "Executive",
    "Exposure Management",
    "R&D",
    "Underwriting",
    "Loss Adjusting",
    "Other"
]

export const INDUSTRIES = [
    "Insurance",
    "Reinsurance",
    "Emergency Management and Disaster Response",
    "Financial Services",
    "Logistics and Supply Chain Management",
    "Energy and Utilities",
    "Travel and Tourism",
    "Agriculture and Food Supply",
    "Construction and Real Estate",
    "Technology and Telecommunications",
    "Media and Journalism",
    "Healthcare and Pharmaceuticals",
    "Environmental and Climate Research",
    "Education and Training",
    "Defence and Security",
    "Other"
]