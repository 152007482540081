import React, { CSSProperties, useState } from "react";

import classes from "./ScrollableText.module.css";

export interface OwnProps {
    color?: string;
    text: string;
    className?: string;
    id?: string;
}

const ScrollableText: React.FC<OwnProps> = ({
    color,
    text,
    className,
    id
}: OwnProps) => {
    let [margin, setMargin] = useState(0);
    let [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
    let style: CSSProperties = { color: color, marginLeft: margin };

    return (
        <div className={classes.Container} id={id}>
            <p
                className={className ?? ""}
                style={style}
                title={text}
                onMouseOver={(e) => {
                    let containerWidth =
                        e.currentTarget.parentElement!.scrollWidth;
                    let pWidth = e.currentTarget.scrollWidth;
                    let textWidth = containerWidth - pWidth;
                    let AnotherIntervalId = setInterval(() => {
                        if (pWidth > containerWidth) {
                            setMargin((margin -= 1));
                            if (margin < textWidth) {
                                clearInterval(AnotherIntervalId);
                            }
                        }
                    }, 20);
                    setIntervalId(AnotherIntervalId);
                }}
                onMouseOut={() => {
                    clearInterval(intervalId!);
                    setMargin(0);
                }}
            >
                {text}
            </p>
        </div>
    );
};

export default ScrollableText;
