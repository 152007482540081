import React from 'react'
import classes from "./Stepper.module.css"
import cx from "classnames"

interface OwnProps {
    numberOfSteps: number;
    activeStep: number;
}

const Stepper: React.FC<OwnProps> = (props) => {

    const steppers = []

    for (let i = 0; i < props.numberOfSteps ; i++) {
        steppers.push(
            <div className={cx(
                {[classes.ActiveStepper]: props.activeStep === i+1},
                {[classes.CompletedStepper]: props.activeStep > i+1},
                classes.Stepper
            )}></div>
        )
    }
    
    return (
        <div className={classes.StepperContainer}>
            {steppers}
        </div>
    )
}

export default Stepper