import { SecureRoute } from "@okta/okta-react";
import React, { useEffect } from "react";
import {
  Switch, useHistory, useLocation, useRouteMatch,
} from "react-router-dom";
import NamesAndIndustry from "./NamesAndIndustry";
import LinesOfBusiness from "./LinesOfBusiness";
import JobRoles from "./JobRoles";
import Region from "./Region";
import Stepper from "components/_Library/Stepper/Stepper";
import { AnimatePresence } from "framer-motion"
import { isNotEmpty, useForm } from "@mantine/form";
import { useDispatch, useSelector } from "react-redux";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { RootState } from "store/store";
import { useLocalStorage } from "@mantine/hooks";
import { useUpdateUserDetailsMutation } from "crud/hooks/users";
import { setUser } from "store/user/userActions";
import { ExtendedAccessManagementUser } from "types/auth";

export interface FormValues {
    first_name: string;
    last_name: string;
    industry: string;
    role_division: string | null;
    region: string | null;
    lines_of_business: string[];
    job_role: string;
}

export const LOCAL_STORAGE_ONBOARDING_KEY = "onboarded-user-details";

export default function Onboarding() {

    const location = useLocation();
    const history = useHistory();
    const {path} = useRouteMatch();
    const dispatch = useDispatch();

    const user: ExtendedAccessManagementUser = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user!
    )

    const defaultUserDetails = {
        first_name: user?.first_name ?? "",
        last_name: user?.last_name ?? "",
        industry: user?.industry ?? "Insurance",
        role_division: user?.role_division ?? null,
        region: user?.region ?? null,
        lines_of_business: user?.lines_of_business ?? [],
        job_role: user?.job_role ?? "",
    }

    const [updatedUserDetails, setUpdatedUserDetails, removeUpdatedUserDetails] = useLocalStorage({
        key: LOCAL_STORAGE_ONBOARDING_KEY,
        defaultValue: defaultUserDetails
    })

    const form = useForm<FormValues>({
        initialValues: updatedUserDetails,
        validate: {
            first_name: isNotEmpty("First name is required"),
            last_name: isNotEmpty("Last name is required"),
            industry: isNotEmpty("Industry is required"),
            lines_of_business: isNotEmpty("Lines of business is required"),
            role_division: isNotEmpty("Role division is required"),
        }
    })

    useEffect(() => {
        //If user falls on onboarding root page, push to first step
        location.pathname === "/onboarding" && history.push("/onboarding/1"); 
        if (user) {
            if (localStorage.getItem(LOCAL_STORAGE_ONBOARDING_KEY)) {
                const newFormDetails: FormValues = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ONBOARDING_KEY)!)
                form.setValues(newFormDetails)
            } else {
                form.setValues(defaultUserDetails)
            }
        }       
    }, [location, history, user]); //eslint-disable-line

    const getActiveStep = () => {
        const path = location.pathname;
        const step = path.split("/")[2];
        return parseInt(step);
    }

    const updateLocalStorage = () => {
        if (["Insurance", "Reinsurance"].includes(form.getInputProps('industry').value)) {
            setUpdatedUserDetails(form.values);
        } else {
            let resetInsuranceSpecificFields = {lines_of_business: [], role_division: null};
            setUpdatedUserDetails({...form.values, ...resetInsuranceSpecificFields});
        }
    }

    const submitForm = async () => {
        removeUpdatedUserDetails()
        await createUpdateUserMutation.mutateAsync({
            ...form.values,
            last_login_at: new Date().toISOString(),
        });  
    }
   
    const createUpdateUserMutation = useUpdateUserDetailsMutation({
        onSuccess: () => {
            dispatch(
                setUser({
                    ...user,
                    ...form.values
                })
            )
            history.push("/")
        },
    });

    return (
        <>
            <Stepper
                numberOfSteps={4}
                activeStep={getActiveStep()}
            />
            <AnimatePresence>
                <Switch location={location} key={location.pathname}>
                    <SecureRoute
                        path={`${path}/1`}
                        render={() => {
                            return <NamesAndIndustry form={form} updateLocalStorage={updateLocalStorage} /> 
                        }}
                    />
                    <SecureRoute
                        path={`${path}/2`}
                        render={() => {
                            return <LinesOfBusiness form={form} updateLocalStorage={updateLocalStorage} />
                        }}
                    />
                    <SecureRoute
                        path={`${path}/3`}
                        render={() => {
                            return <JobRoles form={form} updateLocalStorage={updateLocalStorage} />
                        }}
                    />
                    <SecureRoute
                        path={`${path}/4`}
                        render={() => {
                            return <Region form={form} updateLocalStorage={updateLocalStorage} submitForm={submitForm}/>
                        }}
                    />
                </Switch>
            </AnimatePresence>
        </>
        
    );
}
