import React, { useEffect } from 'react'
import classes from "./Onboarding.module.css"
import { useHistory } from 'react-router-dom'
import { motion } from "framer-motion"
import Icon from '@mdi/react'
import { mdiArrowDownCircleOutline } from '@mdi/js'
import { Select, TextInput } from '@mantine/core'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { getStoreAtNamespaceKey } from 'store/storeSelectors'
import { INDUSTRIES } from 'store/user/utils'
import { UseFormReturnType } from '@mantine/form'
import { FormValues } from './Onboarding'

interface NamesAndIndustryProps {
    form:  UseFormReturnType<FormValues>
    updateLocalStorage: () => void
}

const NamesAndIndustry: React.FC<NamesAndIndustryProps> = ({form, updateLocalStorage}) => {

    const history = useHistory()

    const user = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user
    )

    useEffect(() => {
        if (user?.industry) {
            history.push("/")
        } 
    }, [user, history])

    const handleNextStep = () => {
        const validationResult = form.validate()
        const validToProceed = !['first_name', 'last_name', 'industry'].some(key => key in validationResult.errors);

        if (validToProceed) {
            updateLocalStorage()
            history.push(["Insurance", "Reinsurance"].includes(form.getInputProps('industry').value) ? "/onboarding/2" : "/onboarding/3") 
        } 
    }

    const displayForm = () => {
        return (
            <motion.div 
                className={classes.Container}
                initial={{
                    y: "100vh",
                    opacity: 0,
                }}
                animate={{
                    y: "0",
                    opacity: 1,
                    transitionDuration: "0.5s"
                }}
                exit={{
                    y: "-100vh",
                    opacity: 0,
                }}
            >
                <div className={classes.HeaderContainer}>
                    <div className={classes.Logo}></div>
                    <div className={classes.HeaderText}>
                        <h2 className={classes.Title}>Let's customise your experience in just a couple of steps.</h2>
                        <p className={classes.SubTitle}>It'll take just 30 seconds!</p>
                    </div> 
                </div>
                <div className={classes.FormContainer}>
                    <div className={classes.TwoColumnInput}>
                        <TextInput 
                            label="First Name"
                            required 
                            variant='twoColumn' 
                            {...form.getInputProps('first_name')}
                        />
                        <TextInput 
                            label="Last Name"
                            required 
                            variant='twoColumn' 
                            {...form.getInputProps('last_name')}
                        />
                    </div>
                    <Select 
                        data={INDUSTRIES} 
                        label="Industry"
                        required 
                        variant="primaryBg" 
                        maxDropdownHeight={300}
                        {...form.getInputProps('industry')} 
                    />
                </div>
                <div 
                    className={classes.ContinueContainer}
                    onClick={handleNextStep}
                >
                    <Icon className={classes.ContinueIcons} path={mdiArrowDownCircleOutline} size={5}/>
                </div>
        
            </motion.div>
        )
    }

    return displayForm()
}

export default NamesAndIndustry