import React from "react";
import classes from "../AccountModal.module.css";
import { AccessManagementUserBase } from "types/auth";
import cx from "classnames"
import Button from "components/_Library/Button/Button";
import { useUpdateUserDetailsMutation } from "crud/hooks/users";
import { useUserMeQuery } from "crud/hooks/me";
import { Select, TextInput } from "@mantine/core";
import { NotificationRegions } from "store/system/systemTypes";
import ControlSection from "components/_Library/ControlSection/ControlSection";
import { INDUSTRIES, LINES_OF_BUSINESS, ROLE_DIVISIONS } from "store/user/utils";
import { isNotEmpty, useForm } from "@mantine/form";
import { FormValidationResult } from "@mantine/form/lib/types";

interface OwnProps {
    user: AccessManagementUserBase | undefined
}

type DetailsTabProps = OwnProps;

const DetailsTab: React.FC<DetailsTabProps> = ({ user }) => {

    const { refetch } = useUserMeQuery();
    
    const form = useForm({
        initialValues: {
            first_name: user?.first_name!,
            last_name: user?.last_name!,
            job_role: user?.job_role ?? null,
            industry: user?.industry!,
            region: user?.region ?? null,
            lines_of_business: user?.lines_of_business ?? [],
            role_division: user?.role_division ?? null
        },
        validate: {
            first_name: isNotEmpty("First name is required"),
            last_name: isNotEmpty("Last name is required"),
            industry: isNotEmpty("Industry is required"),
            lines_of_business: isNotEmpty("Lines of business is required"),
            role_division: isNotEmpty("Role division is required"),
        }
    })

    const createUpdateUserMutation = useUpdateUserDetailsMutation({
        onSuccess: () => refetch(),
    });

    const checkIfValidToProceed = (formErrors: FormValidationResult) => {
        const requiredFieldsForInsurance = ['first_name', 'last_name', 'industry', 'role_division', 'lines_of_business'];
        const requiredFieldsForNonInsurance = ['first_name', 'last_name', 'industry'];

        if (["Insurance", "Reinsurance"].includes(form.getInputProps('industry').value)) {
            return !requiredFieldsForInsurance.some(key => key in formErrors.errors);
        } else {
            return !requiredFieldsForNonInsurance.some(key => key in formErrors.errors);
        }
    }

    const handleUserUpdate = async () => {

        const formErrors = form.validate();
        const validToProceed = checkIfValidToProceed(formErrors)

        if (validToProceed) {
            await createUpdateUserMutation.mutateAsync({
                ...form.values,
                last_login_at: new Date().toISOString(),
            });
            form.setErrors({})
        }
    };

    return (
        <div className={classes.ContentContainer}>
            <div className={cx(classes.Input, classes.DualColumnInputs)}>
                <TextInput
                    label={"First Name"}
                    required
                    variant={"twoColumn"}
                    {...form.getInputProps("first_name")}
                />
                <TextInput
                    label={"Last Name"}
                    required
                    variant={"twoColumn"}
                    {...form.getInputProps("last_name")}
                />
            </div>
            <div className={classes.Input}>
                <Select
                    label={"Industry"}
                    variant={"primaryBg"}
                    data={INDUSTRIES}
                    required
                    value={form.getInputProps("industry").value}
                    onChange={(event) => {
                        if (!["Insurance", "Reinsurance"].includes(event!)) {
                            form.setValues({
                                lines_of_business: [],
                                role_division: null,
                                industry: event!,
                            })
                        } else {
                            form.setFieldValue("industry", event!)
                        }
                        
                    }}
                />
            </div>
            {
                ["Insurance", "Reinsurance"].includes(form.getInputProps("industry").value) &&
                    <ControlSection
                        error={form.getInputProps("lines_of_business").error}
                        title="Lines of Business"
                        items={LINES_OF_BUSINESS}
                        required
                        selectedItems={
                            form.getInputProps("lines_of_business").value
                        }
                        onIndicatorClick={
                            () => {
                                if (form.getInputProps("lines_of_business").value.length !== 0) {
                                    form.setFieldValue("lines_of_business", [])

                                } else {
                                    form.setFieldValue("lines_of_business", LINES_OF_BUSINESS)
                                }
                            }
                        }
                        onChange={
                            (lines_of_business) => {
                                form.setFieldValue("lines_of_business", lines_of_business)
                            }
                        }
                        cols={3}
                        width="100%"
                    />
            }
            
            <div className={cx(classes.Input, classes.DualColumnInputs)}>
                {
                    ["Insurance", "Reinsurance"].includes(form.getInputProps("industry").value) && 
                        <Select
                            label={"Role Division"}
                            data={ROLE_DIVISIONS}
                            required
                            variant={"twoColumn"}
                            {...form.getInputProps("role_division")}
                        />
                }
                
                <TextInput
                    label={"Job Title"}
                    variant={"twoColumn"}
                    {...form.getInputProps("job_role")}
                />
            </div>
            <div className={classes.Input}>
                <Select
                    label={"Region You're Based In"}
                    variant={"primaryBg"}
                    data={NotificationRegions}
                    {...form.getInputProps("region")}
                />
            </div>
            <div className={classes.ButtonContainer}>
                <Button 
                    onClick={() => {
                        handleUserUpdate()
                    }} 
                    size={{width: "20rem"}}
                >
                    Save Changes
                </Button>
            </div>
        </div>
    )
};

export default DetailsTab;