import React, {
    useEffect,
    useState,
    useCallback,
    CSSProperties,
    useRef,
    RefObject,
    ReactNode, 
} from "react";
import { mdiPanHorizontal } from "@mdi/js";
import { useSelector } from "react-redux";
import MapGL, {
    MapEvent,
    MapMouseEvent,
    MapRef,
    MapWheelEvent,
} from "react-map-gl";
import Icon from "@mdi/react";

import { RootState } from "../../../../../../../store/store";
import { clamp } from "../../../../../../../utils/Maths";
import cx from "classnames";
import classes from "./MegaMap.module.css";
import "mapbox-gl/dist/mapbox-gl.css";
import ControlWrapper from "components/Pages/Report/DashboardComponents/Map/Mapping/Controls/ControlWrapper/ControlWrapper";
import { getStoreAtNamespaceKey } from "../../../../../../../store/storeSelectors";
import { getLayers } from "../../../../../../../utils/Layers";
import MapIcons from "assets/images/MapIcons";
import { CapitaliseWord } from "utils/String";
import { useHistory, useLocation } from "react-router-dom";
import mapboxgl, { MapLayerMouseEvent } from "mapbox-gl";
import { LayersConfig, MapType } from "store/report/reportTypes";
import useURLQueryParams from "crud/hooks/useURLQueryParams";

type MegaMapProps = {
    mapType: MapType;
    interactiveLayerIds: string[]; // list of hoverable/clickable layers.
    basemaps: string[]; // left/right map styling (sat/light/dark)
    mapboxToken: string;
    layers: (React.JSX.Element | null)[];
    layersConfig?: LayersConfig;
    handleMouseMove: (
        event: MapLayerMouseEvent,
        mapRef?: RefObject<MapRef>,
    ) => void;
    handleMapOnClick: (event: MapLayerMouseEvent) => void;
    controls: { [key: string]: ReactNode };
    leftMapRef: RefObject<MapRef>;
    rightMapRef: RefObject<MapRef>;
    popup: ReactNode;
    contextMenu: ReactNode;
    additionalLayers: ReactNode;
    additionalInteractiveLayerIds: string[];
    onContextMenu: (
        mapSide: RefObject<MapRef>,
        event: MapLayerMouseEvent,
    ) => void;
    insightLayers: JSX.Element | null;
    insightsDonutMarker: JSX.Element;
    mapSearchMarker: JSX.Element;
};

const MegaMap: React.FC<MegaMapProps> = (props) => {
    const insightsViewOn = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").insightsViewOn,
    );
    const clusterToggle = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").clusterToggle,
    );
    const assessmentType = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").assessmentType,
    );
    const locationData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").locationData,
    );
    const isPreview = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "report").isPreview,
    );

    const params = useURLQueryParams();
    const page = params.get("page");

    const [isCursorHovering, setIsCursorHovering] = useState<boolean>(false);

    const [sliderPosPercent, setSliderPosPercent] = useState<number>(50);
    const [mapContainerWidth, setMapContainerWidth] = useState<number>(
        window.innerWidth, 
    );

    const [viewState, setViewState] = useState({
        latitude: 0,
        longitude: 0,
        zoom: 2,
    });

    const [leftMapStyle, setLeftMapStyle] = useState<CSSProperties>({});
    const [rightMapStyle, setRightMapStyle] = useState<CSSProperties>({
        width: "100%",
    });

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        switch (props.mapType) {
            case "dual":
                setRightMapStyle({
                    width: "50%",
                });
                setLeftMapStyle({
                    clipPath: "none",
                    width: "50%",
                    borderRight: "2px solid var(--highlight-color)",
                });
                break;
            case "compare":
                setRightMapStyle({});
                setLeftMapStyle({
                    clipPath: `polygon(0 0, 50% 0, 50% 100%, 0 100%)`,
                    position: "absolute",
                    top: 0,
                    left: 0,
                });
                setSliderPosPercent(50);

                break;
            case "single":
                setRightMapStyle({
                    width: "100%",
                    height: "100%",
                });
                setLeftMapStyle({
                    width: 0,
                    marginLeft: 0,
                    clipPath: "none",
                });
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mapType]);

    useEffect(() => {
        if (page === "report" || !page) {
            // Adding ID to the maps zoom in/out controls - We don't have access to the element.
            // This is used for Playwright testing.
            document.getElementsByClassName("mapboxgl-ctrl-zoom-in")[0]?.setAttribute("id", "map-zoom-in");
            document.getElementsByClassName("mapboxgl-ctrl-zoom-out")[0]?.setAttribute("id", "map-zoom-out");

            setTimeout(() => {
                if (props.rightMapRef.current) {
                    props.rightMapRef.current.getMap().resize();
                }
                if (props.leftMapRef.current) {
                    props.leftMapRef.current.getMap().resize();
                }
            }, 16);
        }
    }, [props.mapType, props.leftMapRef, props.rightMapRef, page]);

    useEffect(() => {
        const loadMapImages = async () => {
            if (props.leftMapRef.current && props.rightMapRef.current) {
                let leftMap = props.leftMapRef.current.getMap();
                let rightMap = props.rightMapRef.current.getMap();
                if (rightMap && leftMap) {
                    for (let [imageName, image] of Object.entries(MapIcons)) {
                        rightMap.loadImage(image, (err, image) => {
                            if (err) throw err;
                            if (
                                leftMap &&
                                !leftMap.hasImage(imageName) &&
                                image
                            ) {
                                leftMap.addImage(imageName, image);
                            }
                            if (!rightMap.hasImage(imageName) && image) {
                                rightMap.addImage(imageName, image);
                            }
                        });
                    }
                }
            }
        };
        loadMapImages();
    }, [props.leftMapRef, props.rightMapRef]);

    const preventGlobalMouseEvents = () => {
        document.body.style.pointerEvents = "none";
    };

    const restoreGlobalMouseEvents = () => {
        document.body.style.pointerEvents = "auto";
    };

    const updateURL = useCallback(
        (lat: number, lng: number, zoom: number) => {
            if (isPreview) {
                return;
            }

            const pathSegments = location.pathname.split("/");
            const reportIndex = pathSegments.indexOf(pathSegments[1]);

            const newPathname = `/${pathSegments[1]}/${
                pathSegments[reportIndex + 1]
            }/${[lng, lat, zoom]
                .map((number) => number?.toFixed(5))
                .join("/")}`;

            if (newPathname !== location.pathname) {
                history.push({
                    pathname: newPathname,
                    search: location.search,
                });
            }
        },
        [history, isPreview, location.pathname, location.search],
    );

    const onMouseMove = useCallback((event: MouseEvent) => {
        const mapContainerElem = document.getElementsByClassName("compare")[0];
        const pointerPos = (event.clientX / mapContainerElem.clientWidth) * 100;

        const offset = clamp(0, 100, pointerPos);
        setSliderPosPercent(offset);
    }, []);

    const onMouseUp = useCallback(() => {
        restoreGlobalMouseEvents();
        document.removeEventListener("mouseup", onMouseUp);
        document.removeEventListener("mousemove", onMouseMove);
    }, [onMouseMove]);

    const onMapResize = useCallback((e: MapEvent) => {
        setMapContainerWidth(e.target.getCanvas().clientWidth);
    }, []);

    const removeInvisibleLayersFromInteractiveIds = useCallback(
        (visibleLayers: React.JSX.Element[]) => {
            let interactiveIds: string[] = [];
            let menuIndex = props.layersConfig!.menuIndex;
            let layersIndexResult = getLayers(menuIndex);
            visibleLayers.forEach((layer: React.ReactElement) => {
                layersIndexResult.forEach((layerFromIndex) => {
                    if (layerFromIndex.layerSource === layer.key) {
                        interactiveIds.push(layerFromIndex.layerName);
                    }
                });
            });
            return interactiveIds;
        },
        [props.layersConfig],
    );

    const onMouseDown = useCallback(() => {
        preventGlobalMouseEvents();
        document.addEventListener("mouseup", onMouseUp);
        document.addEventListener("mousemove", onMouseMove);
    }, [onMouseMove, onMouseUp]);

    useEffect(() => {
        setLeftMapStyle({
            clipPath: `polygon(0 0, ${sliderPosPercent}% 0, ${sliderPosPercent}% 100%, 0 100%)`,
            position: "absolute",
            top: 0,
            left: 0,
        });
    }, [sliderPosPercent]);

    let assessmentLayerName = `${CapitaliseWord(assessmentType)} Assessment`;
    let interactiveInsightsRight: string[] = [];
    let interactiveInsightsLeft: string[] = [];

    if (locationData && ["both", "right"].includes(insightsViewOn)) {
        interactiveInsightsRight.push(assessmentLayerName);
        if (clusterToggle) {
            interactiveInsightsRight.push("insightsClusterCircle");
        }
    }

    if (locationData && ["both", "left"].includes(insightsViewOn)) {
        interactiveInsightsLeft.push(assessmentLayerName);
        if (clusterToggle) {
            interactiveInsightsLeft.push("insightsClusterCircle");
        }
    }

    let rightLayers: React.JSX.Element[] = [];
    let leftLayers: React.JSX.Element[] = [];

    let interactiveLayersRight: string[] = [];
    let interactiveLayersLeft: string[] = [];

    if (props.layers && props.layersConfig) {
        rightLayers = props.layers.filter(
            (elem) =>
                elem &&
                ["both", "right"].includes(
                    // @ts-ignore: checked in if above
                    props.layersConfig.sources[elem.key!].viewOn,
                ),
        ) as React.JSX.Element[];

        leftLayers = props.layers.filter(
            (elem) =>
                elem &&
                ["both", "left"].includes(
                    // @ts-ignore: checked in if above
                    props.layersConfig.sources[elem.key!].viewOn,
                ),
        ) as React.JSX.Element[];

        interactiveLayersRight =
            removeInvisibleLayersFromInteractiveIds(rightLayers);
        interactiveLayersLeft =
            removeInvisibleLayersFromInteractiveIds(leftLayers);
    }

    let menuHidden = useSelector(
        (state) =>
            getStoreAtNamespaceKey(state, "report").menuConfig.menuHidden,
    );

    useEffect(() => {
        let tabs = document.getElementById("TabsSideDrawer");

        if (tabs) {
            let intervalId: any;
            const resizeMap = () => {
                props.rightMapRef.current?.getMap().resize();
                if (props.mapType !== "single") {
                    props.leftMapRef.current?.getMap().resize();
                }
            };
            tabs.ontransitionend = (e) => {
                if (e.target !== tabs) return;
                clearInterval(intervalId);
            };

            tabs.ontransitionrun = (e) => {
                if (e.target !== tabs) return;
                intervalId = setInterval(resizeMap, 16);
            };
        }
    }, [menuHidden, props.leftMapRef, props.mapType, props.rightMapRef]);

    const mapContainerRef = useRef<HTMLDivElement>(null);

    return (
        <React.Fragment>
            <div
                className={cx(classes.MegaMap, props.mapType)}
                ref={mapContainerRef}
            >
                {props.mapType !== "single" && (
                    <MapGL
                        {...viewState}
                        onLoad={async (e) => {
                            e.target.resize();
                        }}
                        onMove={(evt) => setViewState(evt.viewState)}
                        style={leftMapStyle}
                        reuseMaps
                        ref={props.leftMapRef}
                        mapboxAccessToken={props.mapboxToken}
                        interactiveLayerIds={interactiveLayersLeft
                            .concat(props.additionalInteractiveLayerIds)
                            .concat(interactiveInsightsLeft)}
                        mapStyle={`mapbox://styles/${props.basemaps[0]}`}
                        onMouseMove={(e: MapMouseEvent) =>
                            props.handleMouseMove(e, props.leftMapRef)
                        }
                        onMouseEnter={(e: MapMouseEvent) =>
                            setIsCursorHovering(true)
                        }
                        onMouseLeave={(e: MapMouseEvent) =>
                            setIsCursorHovering(false)
                        }
                        cursor={isCursorHovering ? "pointer" : "auto"}
                        onClick={props.handleMapOnClick}
                        onWheel={(e: MapWheelEvent) =>
                            updateURL(
                                e.target.getCenter().lat,
                                e.target.getCenter().lng,
                                e.target.getZoom(),
                            )
                        }
                        onDblClick={(e: MapMouseEvent) =>
                            updateURL(
                                e.target.getCenter().lat,
                                e.target.getCenter().lng,
                                e.target.getZoom(),
                            )
                        }
                        onMouseUp={(e: MapMouseEvent) =>
                            updateURL(
                                e.target.getCenter().lat,
                                e.target.getCenter().lng,
                                e.target.getZoom(),
                            )
                        }
                        onContextMenu={(event: MapMouseEvent) => {
                            props.onContextMenu(props.leftMapRef, event);
                        }}
                        dragRotate={false}
                        attributionControl={false}
                    >
                        {leftLayers}
                        {props.additionalLayers}
                        {props.insightsDonutMarker}
                        {["both", "left"].includes(insightsViewOn) &&
                            props.insightLayers}
                        {props.mapSearchMarker}
                        {props.popup}
                        {props.contextMenu}

                        <ControlWrapper position="top-left">
                            {props.controls.tl}
                        </ControlWrapper>
                        <ControlWrapper position="bottom-left">
                            {props.controls.bl}
                        </ControlWrapper>
                        {props.mapType !== "dual" && (
                            <>
                                <ControlWrapper position="bottom-right">
                                    {props.controls.br}
                                </ControlWrapper>
                                <ControlWrapper position="top-right">
                                    {props.controls.tr}
                                </ControlWrapper>
                            </>
                        )}
                    </MapGL>
                )}

                <MapGL
                    reuseMaps
                    style={rightMapStyle}
                    onLoad={async (e) => {
                        e.target.resize();
                        onMapResize(e);
                    }}
                    {...viewState}
                    onMove={(evt) => setViewState(evt.viewState)}
                    mapLib={mapboxgl}
                    ref={props.rightMapRef}
                    mapboxAccessToken={props.mapboxToken}
                    interactiveLayerIds={interactiveLayersRight
                        .concat(props.additionalInteractiveLayerIds)
                        .concat(interactiveInsightsRight)}
                    mapStyle={`mapbox://styles/${props.basemaps[1]}`}
                    onMouseMove={(e: MapMouseEvent) =>
                        props.handleMouseMove(e, props.rightMapRef)
                    }
                    onMouseEnter={(e: MapMouseEvent) =>
                        setIsCursorHovering(true)
                    }
                    onMouseLeave={(e: MapMouseEvent) =>
                        setIsCursorHovering(false)
                    }
                    cursor={isCursorHovering ? "pointer" : "auto"}
                    onClick={props.handleMapOnClick}
                    onWheel={(e: MapWheelEvent) =>
                        updateURL(
                            e.target.getCenter().lat,
                            e.target.getCenter().lng,
                            e.target.getZoom(),
                        )
                    }
                    onDblClick={(e: MapMouseEvent) =>
                        updateURL(
                            e.target.getCenter().lat,
                            e.target.getCenter().lng,
                            e.target.getZoom(),
                        )
                    }
                    onMouseUp={(e: MapMouseEvent) =>
                        updateURL(
                            e.target.getCenter().lat,
                            e.target.getCenter().lng,
                            e.target.getZoom(),
                        )
                    }
                    onContextMenu={(event: MapMouseEvent) => {
                        props.onContextMenu(props.rightMapRef, event);
                    }}
                    dragRotate={false}
                    onResize={onMapResize}
                >
                    {rightLayers}
                    {props.additionalLayers}
                    {["both", "right"].includes(insightsViewOn) &&
                        props.insightLayers}
                    {props.insightsDonutMarker}
                    {props.mapSearchMarker}
                    {props.popup}
                    {props.contextMenu}
                    {props.mapType !== "dual" && (
                        <>
                            <ControlWrapper position="top-left">
                                {props.controls.tl}
                            </ControlWrapper>
                            <ControlWrapper position="bottom-left">
                                {props.controls.bl}
                            </ControlWrapper>
                        </>
                    )}
                    <ControlWrapper position="bottom-right">
                        {props.controls.br}
                    </ControlWrapper>
                    <ControlWrapper position="top-right">
                        {props.controls.tr}
                    </ControlWrapper>
                </MapGL>
                {Boolean(props.mapType === "compare") && (
                    <div
                        className={classes.sliderContainer}
                        style={{
                            left: `${
                                (sliderPosPercent / 100) * mapContainerWidth
                            }px`,
                        }}
                    >
                        <div
                            onMouseDown={onMouseDown}
                            className={classes.slider}
                            id="tourid_CompareMap_Slider"
                        >
                            <Icon path={mdiPanHorizontal} />
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default MegaMap;
